/* eslint-disable max-lines-per-function */
import {
  TypedDocumentNode,
  useApolloClient,
  useSubscription,
} from "@apollo/client";
import { RecognizeTextCommandOutput } from "@aws-sdk/client-lex-runtime-v2";
import { captureException } from "@sentry/react";
import { ChatComponent } from "Components/ChatBox";
import { PresignedTempUrl } from "Components/graphql/fragments/FileUpload";
import { UploadProps } from "antd";
import axios from "axios";
import { useEffect, useMemo, useRef } from "react";
import { LexSendMessageMutation } from "src/graphql-types/graphql";
import { ChatMessage } from "../ChatBox/types";
import { CustomPayload } from "./ChatMessageItem";
import ChatPageNavBar from "./ChatPageNavbar";
import { randomId, useChatReducer } from "./ChatState";
import {
  ASYNC_LAMBDA,
  CHAT_MESSAGE_SUBSCRIPTION,
  SEND_LEX_MESSAGE,
} from "./graphql";

type GqlVariables<T> = T extends TypedDocumentNode<any, infer V> ? V : never;

export function ChatPage() {
  const apolloClient = useApolloClient();

  const [chatState, dispatchChatEvent] = useChatReducer();

  useEffect(() => {
    if (chatState.focus.type === "fileUpload") {
      if (chatState.focus.effect) {
        if (chatState.focus.batch.some(x => x.result && "ok" in x.result)) {
          const requestId = randomId();
          dispatchChatEvent({
            type: "beginRequest",
            requestId,
            send: () => {
              sendUserMessage({
                requestId,
                sessionId: chatState.session.sessionId,
                text: "FilesUploaded",
                conversationId: chatState.conversationId,
                llmOnly: chatState.session.llmOnly,
                sessionAttributes: chatState.sessionAttributes,
              });
            },
          });
        }
      }
    } else if (chatState.focus.type === "request") {
      if (chatState.focus.effect) {
        const { send, requestId } = chatState.focus;
        dispatchChatEvent({
          type: "awaitRequest",
          requestId,
        });
        send();
      }
    } else if (chatState.focus.type === "tickertape") {
      if (chatState.focus.effect) {
        const { requestId, messageId, delay } = chatState.focus;
        const [word, ...words] = chatState.focus.words;
        const message = chatState.focus.message + " " + word;

        dispatchChatEvent({
          type: "updateTickertape",
          requestId,
          messageId,
          message,
          words,
          delay,
          effect: false,
        });

        if (words.length) {
          setTimeout(() => {
            dispatchChatEvent({
              type: "updateTickertape",
              requestId,
              messageId,
              message,
              words,
              delay,
              effect: true,
            });
          }, delay);
        } else {
          dispatchChatEvent({
            type: "endRequest",
            requestId,
          });
        }
      }
    }
  }, [chatState.focus]);

  useEffect(() => {
    if (chatState.scrollToChat) {
      // generally we want to scoll only after other changes have been made
      scrollToChat();
      dispatchChatEvent({
        type: "setScrollToChat",
        scrollToChat: false,
      });
    }
  }, [chatState.scrollToChat]);

  useEffect(() => {
    dispatchChatEvent({
      type: "insertTickertape",
      requestId: randomId(),
      messageId: randomId(),
      message: chatState.session.llmOnly
        ? "How can I help you?"
        : "How can I help you? Type `help` for a list of commands.",
    });
  }, [chatState.session]);

  const chatScrollRef = useRef<HTMLDivElement>(null);
  function scrollToChat() {
    setTimeout(() =>
      chatScrollRef.current?.scrollIntoView({ behavior: "instant" })
    );
  }

  useSubscription(CHAT_MESSAGE_SUBSCRIPTION, {
    variables: {
      sessionId: chatState.session.sessionId,
    },
    onData: ({ data: { data } }) => {
      if (!data) return;
      const { requestId, messageStream, error } = data.chatMessages;

      dispatchChatEvent({
        type: "stream",
        requestId,
        message: messageStream,
        error,
      });
    },
  });

  const sendUserMessage = async (
    variables: GqlVariables<typeof SEND_LEX_MESSAGE>
  ) => {
    try {
      const reply = await apolloClient.mutate({
        mutation: SEND_LEX_MESSAGE,
        fetchPolicy: "no-cache",
        variables,
      });
      if (!reply.data?.lexSendMessage) {
        throw Error("No data");
      }
      const { lexSendMessage } = reply.data;
      onSendUserMessageReply(variables.requestId, lexSendMessage);
    } catch (error) {
      captureException(error);

      dispatchChatEvent({
        type: "endRequest",
        requestId: variables.requestId,
        finalMessages: [
          {
            type: "agent",
            data: {
              type: "error",
              message: "There was a problem processing your request.",
            },
          },
        ],
      });
    }
  };

  function onSendUserMessageReply(
    requestId: string,
    {
      conversationId,
      recognizeTextCommandOutput,
    }: LexSendMessageMutation["lexSendMessage"]
  ) {
    dispatchChatEvent({
      type: "setConversationId",
      conversationId,
    });

    if (recognizeTextCommandOutput) {
      // this is lex response
      const output = recognizeTextCommandOutput as RecognizeTextCommandOutput;
      const { sessionState, requestAttributes = {} } = output;

      console.log({ sessionState, requestAttributes });

      // preserve the session attributes
      if (sessionState) {
        dispatchChatEvent({
          type: "setSessionAttributes",
          sessionAttributes: sessionState.sessionAttributes,
        });
        if (sessionState.intent?.name?.toLowerCase().startsWith("fallback")) {
          // ignore the contents of the fallback intent
          return;
        }
      }

      const messages = output.messages?.filter(x => x.content) ?? [];

      function renderFinalMessages() {
        if (!messages.length) return [];
        return messages.map(x => {
          if (x.contentType === "PlainText") {
            return {
              type: "agent" as const,
              data: {
                type: "markdown" as const,
                message: x.content ?? "",
              },
            };
          } else if (x.contentType === "CustomPayload") {
            const content = JSON.parse(x.content ?? "{}") as CustomPayload;
            if (content.type === "ImageResponseCard") {
              return {
                type: "agent" as const,
                data: {
                  type: "imageResponseCard" as const,
                  message: content.data,
                },
              };
            } else {
              throw Error(
                "Unexpected custom payload type: " + JSON.stringify(content)
              );
            }
          } else {
            throw Error("Unexpected content type: " + JSON.stringify(x));
          }
        });
      }

      if ("asyncLambda" in requestAttributes) {
        const { asyncLambda } = requestAttributes;
        const { label, input } = JSON.parse(asyncLambda) as {
          label: string;
          input: Record<string, any>;
        };

        const beginRequestId = randomId();
        dispatchChatEvent({
          type: "chainRequest",
          requestId,
          finalMessages: renderFinalMessages(),
          begin: {
            requestId: beginRequestId,
            send: () => {
              apolloClient.query({
                query: ASYNC_LAMBDA,
                variables: {
                  label,
                  input,
                  sessionId: chatState.session.sessionId,
                  requestId: beginRequestId,
                },
              });
            },
          },
        });
      } else if (
        messages.length &&
        messages.every(x => x.contentType === "PlainText")
      ) {
        const message = messages.map(x => x.content).join("\n\n");

        dispatchChatEvent({
          type: "insertTickertape",
          requestId,
          messageId: randomId(),
          message,
        });
      } else {
        dispatchChatEvent({
          type: "endRequest",
          requestId,
          finalMessages: renderFinalMessages(),
        });
      }
    }
  }

  const handleUserMessage = async (message?: string) => {
    if (typeof message === "undefined") {
      message = chatState.userText.trim();
      dispatchChatEvent({
        type: "setUserText",
        userText: "",
      });
    }
    if (!message) return;

    const requestId = randomId();
    dispatchChatEvent({
      type: "beginRequest",
      requestId,
      send: () => {
        sendUserMessage({
          requestId,
          sessionId: chatState.session.sessionId,
          text: message,
          conversationId: chatState.conversationId,
          llmOnly: chatState.session.llmOnly,
          sessionAttributes: chatState.sessionAttributes,
        });
      },
      finalMessages: [
        {
          type: "user",
          data: {
            type: "markdown",
            message,
          },
        },
      ],
    });
  };

  const customRequest: UploadProps["customRequest"] = async ({
    file,
    onError,
    onProgress,
    onSuccess,
  }) => {
    console.log("file ->", file);
    if (!(file instanceof File)) {
      console.log("Not a file");
      return;
    }
    const filename = file.name;
    console.log("filename ->", filename);

    dispatchChatEvent({
      type: "insertFile",
      file,
      finalMessages: [
        {
          type: "user",
          data: {
            type: "markdown",
            message: `${file.name} added to chat.`,
          },
        },
      ],
    });

    try {
      const {
        data: { presignedTempUrl },
      } = await apolloClient.query({
        query: PresignedTempUrl,
        variables: {
          input: {
            filename,
          },
        },
        fetchPolicy: "no-cache",
      });
      console.log("newFiles ->", filename);

      if (!presignedTempUrl) {
        throw Error("No presignedTempUrl (entitlements?)");
      }
      const { bucket, key, url } = presignedTempUrl;
      if (!bucket || !key || !url) {
        throw Error("No bucket, key, or url (entitlements?)");
      }

      await axios.put(url, file, {
        onUploadProgress: event => {
          const { loaded, total } = event;
          onProgress?.({
            percent: total ? Math.round((loaded / total) * 100) : 0,
          } as any);
        },
      });

      dispatchChatEvent({
        type: "updateFile",
        file,
        result: { ok: { name: file.name, bucket, key } },
      });
    } catch (error) {
      dispatchChatEvent({
        type: "updateFile",
        file,
        result: { error },
      });
    }
  };

  const cancelAgentFocus = () => {
    dispatchChatEvent({
      type: "stop",
    });
  };

  const responseButtonClickAction = (value: string | undefined) => {
    if (value) {
      const requestId = randomId();
      dispatchChatEvent({
        type: "beginRequest",
        requestId,
        send: () => {
          sendUserMessage({
            requestId,
            sessionId: chatState.session.sessionId,
            text: value,
            conversationId: chatState.conversationId,
            llmOnly: chatState.session.llmOnly,
            sessionAttributes: chatState.sessionAttributes,
          });
        },
      });
    }
  };

  const agentFocusMessage: (ChatMessage & { key: string })[] = useMemo(() => {
    return chatState.focus.type === "fileUpload" ||
      chatState.focus.type === "request"
      ? [
          {
            type: "agent" as const,
            data: { type: "spinner" as const },
            key: "spinner",
          },
        ]
      : chatState.focus.type === "streaming" ||
        chatState.focus.type === "tickertape"
      ? [
          {
            type: "agent" as const,
            data: {
              type: "markdown" as const,
              message: chatState.focus.message,
            },
            key: "focus",
          },
        ]
      : [];
  }, [chatState.focus]);

  const chatMessages = useMemo(() => {
    const chatMessages = chatState.finalMessages as (ChatMessage & {
      key?: string;
    })[];
    return agentFocusMessage.length
      ? [...chatMessages, ...agentFocusMessage]
      : chatMessages;
  }, [chatState.finalMessages, agentFocusMessage]);

  return (
    <>
      <ChatPageNavBar />
      <div style={{ margin: "auto", maxWidth: 610 }}>
        <ChatComponent
          chatMessages={chatMessages}
          handleUserMessage={handleUserMessage}
          userText={chatState.userText}
          setUserText={userText =>
            dispatchChatEvent({ type: "setUserText", userText })
          }
          fileUpload={{ customRequest }}
          agentWorking={chatState.focus.type !== "idle"}
          responseButtonClickAction={responseButtonClickAction}
          cancelAgentFocus={cancelAgentFocus}
          onNewChat={() => dispatchChatEvent({ type: "newSession" })}
          agentCharacter={{
            name: "Sirius Chat",
            src: "/assets/images/logo192.png",
          }}
          llmOnly={chatState.session.llmOnly}
          setLlmOnly={llmOnly =>
            dispatchChatEvent({ type: "newSession", llmOnly })
          }
          chatScrollRef={chatScrollRef}
        />
      </div>
    </>
  );
}
