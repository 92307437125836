import React, { useRef } from "react";
import * as types from "_graphql-types/graphql";
import classNames from "classnames";
import LeftSidebarWrapper from "Components/show/documents/left_sidebar_wrapper.js";
import { useSelector } from "react-redux";
import { heightValue } from "Selectors/header_selectors";
import { InfiniteScroller } from "Components/InfiniteScroller";
import NotesCategoriesFilter from "./NotesCategoriesFilter";
import NotesFilters from "./NotesFilters";
import NotesList from "./NotesList";
import { notesIndexContext, useNotesIndex } from "./NotesIndex.context";

export function NotesIndex({
  notesFilters,
  children,
}: {
  notesFilters?: types.NotesFilter;
  children?: JSX.Element;
}): JSX.Element {
  const navBarHeight = useSelector(heightValue);
  const sideBarContainer = useRef(null);

  const notesIndexContextValue = useNotesIndex({ notesFilters });

  return (
    <notesIndexContext.Provider value={notesIndexContextValue}>
      <div id="fund-notes-body" className="fund-notes__body">
        <div className={classNames("sticky-container")} ref={sideBarContainer}>
          <LeftSidebarWrapper
            navBarHeight={navBarHeight}
            getSidebarContainer={() => sideBarContainer.current}
          >
            <NotesCategoriesFilter />
          </LeftSidebarWrapper>
          <div className="sticky-container__content">
            <NotesFilters />
            {children}
            <div className="fund-notes__main-wrap">
              {notesIndexContextValue.error && (
                <div className="notes-errors">
                  {JSON.stringify(notesIndexContextValue.error)}
                </div>
              )}
              {notesIndexContextValue.notes && (
                <NotesList notes={notesIndexContextValue.notes} />
              )}
            </div>
            <InfiniteScroller
              isLoading={notesIndexContextValue.loading}
              hasMore={!!notesIndexContextValue?.nextPage?.hasMore}
              loadMore={notesIndexContextValue.fetchMore}
            />
          </div>
        </div>
      </div>
    </notesIndexContext.Provider>
  );
}
