import { useApolloClient, useQuery } from "@apollo/client";
import { isAuthorized } from "Components/Authorized/authHelpers";
import { InvestmentNotesComponent } from "Components/InvestmentNotes";
import InvestmentTeam from "Components/InvestmentTeam";
import PortfolioInvestments from "Components/PortfolioInvestments";
import { INVESTMENT_SUMMARY_FRAGMENT } from "Components/graphql/fragments/Investment";
import SummaryContainer from "Components/show/SummaryContainer";
import DocumentsPage from "Components/show/documents";
import { updateSelectedTabName as dispatchUpdateSelectedTabName } from "_sirius/src/actions/investment_profile_actions/selected_tab_name_actions";
import { useCurrentUser } from "frontend/src/utils/hooks";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import PortfolioAnalytics from "../analytics/portfolio_analytics_component";
import PortfolioOverview from "./PortfolioOverview";
import { GET_PORTFOLIO_CONFIG } from "./graphql";
import { ClientVisibleTabs } from "src/graphql-types/graphql";

const ALL_TABS_VISIBLE = "ALL_TABS_VISIBLE" as const;

export default function PortfolioSummaryContainer({
  investmentId,
  code,
  investmentName,
  ...rest
}: {
  investmentId: number;
  code: string;
  investmentName: string;
  profileType: "PrivateInvestment" | "PublicInvestment" | "Portfolio";
  portfolioDate: string;
  fundRatings: any[];
  isInActive: boolean;
}) {
  const client = useApolloClient();

  const investment = client.readFragment({
    id: `Investment:${investmentId}`,
    fragment: INVESTMENT_SUMMARY_FRAGMENT,
  });
  if (!investment) return null;
  const user = useCurrentUser();
  const isRcgUser = useMemo(
    () => isAuthorized({ user, checks: ["isRockCreek"] }),
    [user]
  );

  const { isImpact, portalSubmitted } = investment;

  const dispatch = useDispatch();
  const updateSelectedTabName = (selectedTabName: string) => {
    dispatch(dispatchUpdateSelectedTabName(selectedTabName));
  };

  const { data } = useQuery(GET_PORTFOLIO_CONFIG, {
    variables: { id: investmentId },
  });

  const clientVisibleTabs =
    !user.clientView || !data?.porfolioConfigByPortfolioId?.clientVisibleTabs
      ? ALL_TABS_VISIBLE
      : data?.porfolioConfigByPortfolioId?.clientVisibleTabs;

  const _tabContents = () => {
    const commonTabs = {
      overview: () => <PortfolioOverview investmentId={investmentId} />,
      ...(clientVisibleTabs === ALL_TABS_VISIBLE ||
      clientVisibleTabs.includes(ClientVisibleTabs.Analytics)
        ? {
            analytics: () => <PortfolioAnalytics investmentId={investmentId} />,
          }
        : {}),
      ...((isAuthorized({ user, checks: ["investmentPageAccess"] }) &&
        (clientVisibleTabs === ALL_TABS_VISIBLE ||
          clientVisibleTabs.includes(ClientVisibleTabs.Investment))) ||
      !user.clientView
        ? {
            investments: () => (
              <PortfolioInvestments entityId={investmentId} disableActions />
            ),
          }
        : {}),
      ...(clientVisibleTabs === ALL_TABS_VISIBLE ||
      clientVisibleTabs.includes(ClientVisibleTabs.Documents)
        ? {
            documents: () => (
              <DocumentsPage
                investmentId={investmentId}
                // do not include firm documents for portfolios (RCS-6339)
                // firmId={firmId ?? undefined}
                showChat={true}
              />
            ),
          }
        : {}),
      ...(!user.clientView && {
        team: () => <InvestmentTeam investmentId={investmentId} />,
        notes: () => <InvestmentNotesComponent investmentId={investmentId} />,
      }),
    };

    return commonTabs;
  };

  return (
    <SummaryContainer
      tabContents={_tabContents()}
      defaultSelectedTab="overview"
      isImpact={!!isImpact}
      odcSourced={!!portalSubmitted}
      investmentName={investmentName}
      updateSelectedTabName={updateSelectedTabName}
      investmentId={investmentId}
      canGenerateReport={isRcgUser}
      canAddNote={isRcgUser}
      {...rest}
    />
  );
}
