/* eslint-disable max-lines-per-function */
import React from "react";
import { Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "i18next";
import InvestmentSummaryTile from "Components/show/overview/InvestmentSummaryTile";
import SummaryFundAdvisor from "Components/show/overview/SummaryFundAdvisor";
import Classifications from "Components/show/overview/Classifications";
import Status from "Components/show/overview/Status";
import InvestmentFirmTile from "Components/show/overview/InvestmentFirmTile";
import { isMobileView } from "Helpers/window_wrapper";
import InvestmentPrivatePositionsTable from "Components/InvestmentPrivatePositionsTable";
import SummaryContactTile from "Components/show/overview/SummaryContactTile";
import Performance from "Components/Performance";
import { PrivateInvestmentTerms } from "Components/InvestmentTerms";
import InvestmentRatingsNav from "frontend/src/components/InvestmentRatingsNav";
import LiquidationTile from "Components/show/overview/LiquidationTile";
import PrivatePeers from "Components/show/overview/PrivatePeers";
import PrivateInvestmentCard from "./PrivateInvestmentCard";
import FamilyPerformance from "./family_performance";

interface Props {
  investmentId: number;
}
const { TabPane } = Tabs;

function PrivateInvestmentOverview({ investmentId }: Props): JSX.Element {
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();

  const subtabRoute = (subtab: string) => {
    navigate(`${pathname}#${subtab}`);
  };

  return (
    <div className="main-content main-content--lg">
      <Tabs
        defaultActiveKey={!hash ? "general" : hash.replace("#", "")}
        data-cy="private-investment-tabs"
        onChange={subtabRoute}
      >
        <TabPane tab={i18n.t("overview.general")} key="general">
          <div className="overview-page-wrap">
            <div className="overview-page__content">
              <div className="overview-page__top-info">
                <div className="overview-page__top-info-left">
                  <InvestmentSummaryTile investmentId={investmentId} />

                  <div className="invt-tab__section hidden-md-up">
                    <SummaryFundAdvisor
                      investmentId={investmentId}
                      className="fund-advisor--mobile"
                    />
                  </div>

                  <Classifications investmentId={investmentId} />
                  <Status investmentId={investmentId} />
                  <LiquidationTile investmentId={investmentId} />

                  <div className="d-flex flex-wrap justify-between">
                    <InvestmentFirmTile investmentId={investmentId} />
                    <PrivateInvestmentCard investmentId={investmentId} />
                  </div>
                </div>
                <div className="overview-page__top-info-right hidden-md-down">
                  <SummaryFundAdvisor investmentId={investmentId} />

                  {!isMobileView() && (
                    <div id="fund-ratings">
                      <InvestmentRatingsNav
                        showRatings={false}
                        investmentId={investmentId}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="invt-tab__section">
                <SummaryContactTile investmentId={investmentId} />
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tab={i18n.t("overview.performance")} key="performanceprivate">
          <Performance investmentId={investmentId} />
        </TabPane>

        <TabPane
          tab={i18n.t("overview.family_performance")}
          key="family_performance"
        >
          <FamilyPerformance investmentId={investmentId} />
        </TabPane>
        <TabPane tab={i18n.t("overview.peers")} key="private_peers">
          <PrivatePeers investmentId={investmentId} />
        </TabPane>
        <TabPane tab={i18n.t("overview.terms")} key="terms">
          <PrivateInvestmentTerms investmentId={investmentId} />
        </TabPane>
        <TabPane tab={i18n.t("overview.positions")} key="positions">
          <div className="invt-tab__section">
            <InvestmentPrivatePositionsTable investmentId={investmentId} />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default PrivateInvestmentOverview;
