import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

class LeftSidebarWrapper extends Component {
  static get propTypes() {
    return {
      navBarHeight: PropTypes.number.isRequired,
      getSidebarContainer: PropTypes.func,
      className: PropTypes.string,
      children: PropTypes.node.isRequired,
    };
  }

  static get defaultProps() {
    return {
      className: "",
      getSidebarContainer() {},
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      styles: {},
    };
    this.handleScroll = _.throttle(this.setWrapperStyles.bind(this), 100);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleScroll);
    this.handleScroll();
  }

  componentDidUpdate() {
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleScroll);
    window.removeEventListener("scroll", this.handleScroll);
  }

  getTopOffset(node) {
    return !node ? 0 : node.getBoundingClientRect().top;
  }

  getContainerTop() {
    const sidebarContainer = this.props.getSidebarContainer();

    if (!sidebarContainer) {
      return this.getTopOffset(this.component);
    }
    return this.getTopOffset(sidebarContainer);
  }

  setWrapperStyles() {
    this.setState({
      styles: this.wrapperStyles(),
    });
  }

  wrapperStyles() {
    const { navBarHeight } = this.props;
    const offsetTop = this.getTopOffset(this.component);
    const navBarHeightValue = _.toInteger(_.replace(navBarHeight, "px", ""));
    const styles = {
      maxHeight: `calc(100vh - ${offsetTop}px)`,
    };

    if (this.getContainerTop() <= navBarHeightValue) {
      return {
        ...styles,
        top: navBarHeight,
        position: "fixed",
        paddingTop: "24px",
      };
    }
    return styles;
  }

  render() {
    const { className, children } = this.props;
    const { styles } = this.state;

    return (
      <div
        className={`sticky-container__panel ${className}`}
        style={styles}
        ref={el => {
          this.component = el;
        }}
      >
        {children}
      </div>
    );
  }
}

export default LeftSidebarWrapper;
