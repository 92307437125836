import { addFlashNotification } from "_sirius/src/actions/flash_actions";
import i18n from "i18next";
import getAuth from "_sirius/src/services/auth";
import config from "../config/config";

export const handleLogout = () => {
  getAuth(config.OKTA_ISSUER(), config.OKTA_CLIENT_ID()).signOut({
    postLogoutRedirectUri: "/",
  });
};

export const displayErrorFlash = () => {
  addFlashNotification({
    message: i18n.t("errors.default"),
    type: "error",
  });
};
