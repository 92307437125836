import { Col, Spin } from "antd";
import { ChatMarkdown } from "frontend/src/components/Chat/ChatMarkdown";
import { ResponseCard } from "./ResponseCard";
import { ChatMessage } from "../ChatBox/types";
import { ImageResponseCard } from "@aws-sdk/client-lex-runtime-v2";
import React, { ReactNode, useState } from "react";

export type CustomPayload = {
  type: "ImageResponseCard";
  data: ImageResponseCard;
};

function ChatMessageBody({
  message: { data },
  onResponseButtonClick,
}: {
  message: ChatMessage;
  onResponseButtonClick?: (value?: string) => void;
}) {
  const [version, setVersion] = useState(0);

  switch (data.type) {
    case "markdown": {
      return (
        <div data-cy="agent-chat-markdown">
          <ChatMarkdown>{data.message}</ChatMarkdown>
        </div>
      );
    }
    case "react": {
      return <div data-cy="agent-chat-react">{data.message}</div>;
    }
    case "error": {
      return (
        <div
          style={{
            backgroundColor: "rgba(255, 0, 0, 0.5)",
            borderRadius: "8px",
            padding: "10px",
          }}
          data-cy="agent-chat-error"
        >
          {data.message}
        </div>
      );
    }
    case "imageResponseCard": {
      return (
        <div
          data-cy="agent-chat-image"
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          <ResponseCard
            imageResponseCard={data.message}
            onResponseButtonClick={onResponseButtonClick}
          />
        </div>
      );
    }
    case "spinner": {
      return (
        <div data-cy="agent-chat-spinner">
          <Spin size="small" style={{ color: "white" }} />
        </div>
      );
    }
    default:
      const _exhaustiveCheck: never = data;
      throw Error(`Unhandled message type ${JSON.stringify(_exhaustiveCheck)}`);
  }
}

export function ChatMessageItem({
  message,
  onResponseButtonClick,
}: {
  message: ChatMessage;
  onResponseButtonClick?: (value?: string) => void;
}) {
  return (
    <>
      <Col span={2}></Col>
      <Col span={22}>
        <ChatMessageBody
          message={message}
          onResponseButtonClick={onResponseButtonClick}
        />
      </Col>
    </>
  );
}
