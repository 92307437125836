/* eslint-disable max-lines-per-function */
import { CloseOutlined } from "@ant-design/icons";
import { useApolloClient, useLazyQuery, useSubscription } from "@apollo/client";
import { DOCUMENT_SHOW } from "Components/graphql/fragments/Document";
import { RefreshingDocumentSignedUrl } from "Helpers/utils";
import { Button, Spin, notification } from "antd";
import { useState } from "react";
import { ChatComponent } from "Components/ChatBox";
import { handleMessageStream } from "Components/ChatBox/utils";
import { ChatMessage } from "Components/ChatBox/types";
import {
  DOCUMENT_MANY,
  DOCUMENT_QUESTION,
  SPECIFIC_DOCUMENT_QUESTION,
  DOCUMENT_QA_SUBSCRIPTION,
} from "./graphql";

const getSessionId = () =>
  `${Date.now()}::${Math.random().toString(36).slice(2)}`;

const DocumentsChat = ({
  investmentId,
  specificDocumentId,
  setSpecificChatDocumentId,
}: {
  investmentId: number;
  specificDocumentId: number | null;
  setSpecificChatDocumentId: (id: null | number) => void;
}) => {
  const apolloClient = useApolloClient();
  const [sessionId, setSessionId] = useState(getSessionId());
  const [conversationId, changeConversationId] = useState<number | null>(null);
  const [question, changeQuestion] = useState("");
  const [fetchingResponse, setFetchingResponse] = useState(false);
  const [chatHistory, changeChatHistory] = useState<ChatMessage[]>([]);

  const specificDocument = apolloClient.readFragment({
    id: `Document:${specificDocumentId}`,
    fragment: DOCUMENT_SHOW,
  });

  const [getDocuments] = useLazyQuery(DOCUMENT_MANY, {});
  const [allDocumentQuestion, { loading, data: allDocumentData }] =
    useLazyQuery(DOCUMENT_QUESTION, {
      variables: { investmentId, question, sessionId },
      fetchPolicy: "no-cache",
      onError(error) {
        setFetchingResponse(false);
        notification.error({
          message: "Error answering your question, please try again",
          duration: 5,
        });
        changeChatHistory([
          ...chatHistory,
          {
            type: "agent",
            data: {
              type: "error",
              message: "Error answering your question, please try again",
            },
          },
        ]);
      },
      onCompleted(data) {
        if (!data) {
          return;
        }
        const { conversationId: _conversationId } = data.documentQuestion;
        if (_conversationId && _conversationId !== conversationId) {
          changeConversationId(_conversationId);
        }
      },
    });
  const [specificDocumentQuestion, { loading: specificLoading }] = useLazyQuery(
    SPECIFIC_DOCUMENT_QUESTION,
    {
      fetchPolicy: "no-cache",
      onError(error) {
        setFetchingResponse(false);
        notification.error({
          message: "Error answering your question, please try again",
          duration: 5,
        });
        changeChatHistory([
          ...chatHistory,
          {
            type: "agent",
            data: {
              type: "error",
              message: "Error answering your question, please try again",
            },
          },
        ]);
      },
      onCompleted(data) {
        if (!data.documentQuestionSpecific.answer) {
          notification.error({
            message: "Error answering your question, please try again",
            duration: 5,
          });
          return;
        }

        if (
          data.documentQuestionSpecific.conversationId &&
          data.documentQuestionSpecific.conversationId !== conversationId
        ) {
          changeConversationId(data.documentQuestionSpecific.conversationId);
        }
      },
    }
  );

  useSubscription(DOCUMENT_QA_SUBSCRIPTION, {
    variables: {
      sessionId: sessionId,
    },
    skip: !sessionId,
    onData: ({ data: { data: _data } }) => {
      const { messageStream, consultedExcerpts } =
        _data?.questionResponses || {};
      if (!messageStream) return;
      const handleStop = () => {
        const consultedDocs =
          consultedExcerpts?.filter(
            ({ id }, i) =>
              consultedExcerpts.findIndex(({ id: _id }) => _id === id) === i
          ) || [];
        if (
          consultedDocs.length > 0 &&
          !chatHistory.find(
            ({ id }) => id === `consulted-excerpts-${conversationId}`
          )
        ) {
          getDocuments({
            variables: { documentIds: consultedDocs.map(({ id }) => id) },
          }).then(res => {
            const updatedChatHistory = {
              type: "agent",
              id: `consulted-excerpts-${conversationId}`,
              data: {
                type: "react",
                message: (
                  <>
                    <strong>Consulted Documents:</strong>
                    <p>click to download</p>
                    <ul>
                      {consultedDocs.map(({ id }) => {
                        const document = res.data?.documentMany.find(
                          doc => doc?.id === id
                        );
                        if (!document) return null;
                        const { name, signedUrl } = document;
                        return (
                          <li key={id}>
                            <a
                              key={id}
                              href={
                                res.data?.documentMany.find(
                                  doc => doc?.id === id
                                )?.signedUrl || undefined
                              }
                              onClick={e => {
                                e.preventDefault();
                                new RefreshingDocumentSignedUrl(
                                  id,
                                  signedUrl,
                                  apolloClient
                                )
                                  .getActive()
                                  .then(url => {
                                    if (url) window.location.assign(url);
                                  });
                              }}
                            >
                              {name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            } as ChatMessage;

            changeChatHistory(messages => [...messages, updatedChatHistory]);
          });
        }
      };
      handleMessageStream({
        messageStream,
        setFetchingResponse,
        updatePrintedMessages: changeChatHistory,
        handleStopStream: handleStop,
      });
    },
  });

  const askQuestion = async () => {
    specificDocumentId
      ? specificDocumentQuestion({
          variables: {
            documentId: specificDocumentId,
            question,
            conversationId,
            sessionId,
          },
        })
      : allDocumentQuestion({
          variables: { question, investmentId, conversationId, sessionId },
        });
    setFetchingResponse(true);
    changeChatHistory([
      ...chatHistory,
      { type: "user", data: { type: "markdown", message: question } },
    ]);
    changeQuestion("");
  };

  return (
    <>
      {specificDocument && (
        <>
          Asking questions about: {specificDocument?.name}
          <Button
            type="primary"
            shape="circle"
            icon={<CloseOutlined />}
            size="small"
            style={{ backgroundColor: "red", borderWidth: 0, marginLeft: 4 }}
            onClick={() => {
              setSpecificChatDocumentId(null);
            }}
          />
        </>
      )}
      <ChatComponent
        chatMessages={[
          ...chatHistory,
          ...(fetchingResponse || specificLoading || loading
            ? [
                {
                  type: "agent" as const,
                  data: {
                    type: "react" as const,
                    message: <Spin size="small" style={{ color: "white" }} />,
                  },
                },
              ]
            : []),
        ]}
        userText={question}
        setUserText={changeQuestion}
        handleUserMessage={askQuestion}
        agentWorking={fetchingResponse}
        cardStyles={{ height: "50vh" }}
        onNewChat={() => {
          changeChatHistory([]);
          changeConversationId(null);
          setSessionId(getSessionId());
        }}
        agentCharacter={{
          name: "Document Chat",
          src: "/assets/images/docchatIcon.png",
        }}
      />
    </>
  );
};

export default DocumentsChat;
