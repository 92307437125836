import React, { useRef } from "react";
import * as types from "_graphql-types/graphql";
import classNames from "classnames";
import { InfiniteScroller } from "Components/InfiniteScroller";
import FieldsActions from "./FieldsFilters";
import FieldsList, { getFieldTitle } from "./FieldsList";
import { fieldsIndexContext, useFieldsIndex } from "./FieldsIndex.context";
import { downloadSpreadsheet, SheetFormat } from "Helpers/downloadSpreadsheet";
import i18next from "i18next";

const DOWNLOAD_LIMIT = 100;

export function FieldsIndex({
  fieldsFilters,
}: {
  fieldsFilters?: types.FieldFilter;
}): JSX.Element {
  const sideBarContainer = useRef(null);

  const fieldsIndexContextValue = useFieldsIndex({ fieldsFilters });

  const download = (format: SheetFormat) =>
    fieldsIndexContextValue.fields &&
    downloadSpreadsheet(
      [
        {
          name: "Diligence Search",
          data: [
            [
              "Section",
              "Display Name",
              "Question",
              "Value",
              "State",
              "Owner Name",
              "OwnerType",
              "OwnerId",
              "Author",
              "Modify Date",
            ],
            ...fieldsIndexContextValue.fields
              .slice(0, DOWNLOAD_LIMIT)
              .map(field => [
                field.fieldMeta?.section,
                field.fieldMeta?.displayName,
                field.fieldMeta?.questionText,
                field.value,
                field.state === null
                  ? i18next.t("fields.state.published")
                  : i18next.t(`fields.state.${field.state}`),
                field.owner?.name,
                i18next.t(`fields.ownerType.${field.ownerType}`),
                field.ownerId,
                field.modifyUser,
                field.modifyDate,
              ]),
          ],
        },
      ],
      `Diligence Search`,
      format
    );

  return (
    <fieldsIndexContext.Provider value={fieldsIndexContextValue}>
      <div
        className={classNames("sticky-container")}
        ref={sideBarContainer}
        data-cy="fields-index"
      >
        <FieldsActions download={download} />
        <div>
          {fieldsIndexContextValue.error && (
            <div>{JSON.stringify(fieldsIndexContextValue.error)}</div>
          )}
          {fieldsIndexContextValue.fields && (
            <FieldsList fields={fieldsIndexContextValue.fields} />
          )}
          <InfiniteScroller
            isLoading={fieldsIndexContextValue.loading}
            hasMore={!!fieldsIndexContextValue?.nextPage?.hasMore}
            loadMore={fieldsIndexContextValue.fetchMore}
          />
        </div>
      </div>
    </fieldsIndexContext.Provider>
  );
}
