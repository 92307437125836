import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { isMainHeaderHidden as isMainHeaderHiddenSelector } from "Selectors/header_selectors";
import { editMode as editModeSelector } from "Selectors/investment_edit_mode_selectors.js";

import { NavigationSidebar } from "Components/NavigationSidebar";
import { NavBar } from "Components/navbar";
import SideBar from "Components/Sidebar";

interface LayoutOptions {
  header?: string;
  body?: string;
}
interface Layout {
  centered: LayoutOptions;
  tile: LayoutOptions;
}

const layouts: Layout = {
  centered: {
    body: "workspace__with_gutters",
  },
  tile: {
    header: "workspace-header__without_gutters",
  },
};

function MainBody({
  children,
  type = "tile",
}: {
  children: React.ReactNode;
  type: keyof Layout;
}) {
  return (
    <div
      className={classNames(
        `workspace workspace__with_gutters`,
        layouts[type].body
      )}
    >
      <div
        id="workspace-header-yield"
        className={classNames(
          `workspace-header__without_gutters`,
          layouts[type].header
        )}
      />
      {children}
    </div>
  );
}

function MainLayoutComponent({
  children,
  type,
  style,
}: {
  children: React.ReactNode;
  type: keyof Layout;
  style?: React.CSSProperties;
}) {
  const isMainHeaderHidden = useSelector((state: any) =>
    isMainHeaderHiddenSelector(state)
  );
  const editMode = useSelector((state: any) => editModeSelector(state));
  const favoritesSidebarExpanded = useSelector(
    (state: any) => state.app.favoritesSidebarExpanded
  );

  return (
    <div
      id="main-container"
      className={classNames("main-container", "h-100", {
        "cms-edit-mode": editMode,
        "cms-edit-mode--scrolled-down": isMainHeaderHidden,
      })}
      style={style}
    >
      <NavigationSidebar />

      <NavBar />

      <SideBar />

      <div
        className={classNames("workspace-wrapper", {
          "workspace-wrapper__with-favorites-panel": favoritesSidebarExpanded,
        })}
      >
        <MainBody type={type}>{children}</MainBody>
      </div>
    </div>
  );
}

export default MainLayoutComponent;
