import { UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Divider, Typography } from "antd";
import { useCurrentUser } from "frontend/src/utils/hooks";
const { Text } = Typography;

export function ChatMessageHead({
  type,
  separator,
  agentCharacter,
}: {
  type: "agent" | "user";
  separator?: boolean;
  agentCharacter: {
    name: string;
    src: string;
  };
}) {
  const currentUser = useCurrentUser();

  return (
    <>
      {separator && <Divider style={{ marginTop: 0 }} />}
      <Col span={2}>
        {type === "agent" ? (
          <>
            <Avatar
              size="small"
              shape="square"
              src={agentCharacter.src}
              style={{ backgroundColor: "white" }}
            />
          </>
        ) : (
          <>
            <Avatar size="small" icon={<UserOutlined />} />
          </>
        )}
      </Col>
      <Col span={22}>
        <Text strong>
          {type === "agent" ? (
            <>{agentCharacter.name}</>
          ) : (
            <>{currentUser?.username || "You"}</>
          )}
        </Text>
      </Col>
    </>
  );
}
