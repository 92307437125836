import React from "react";
import { subMonths } from "date-fns";
import { gql } from "_graphql-types/gql";
import { useApolloClient, useQuery } from "@apollo/client";
import Irr from "Components/Performance/PrivatePerformance";
import RelativePerformance from "../analytics/components/RelativePerformance";

import { GET_PORTFOLIO_CONFIG } from "./graphql";

const INVESTMENT_SUMMARY = gql(/* GraphQL */ `
  fragment InvestmentSummaryAttributeFragment on Investment {
    name
    id
    inceptionDate
    assetClass {
      id
      name
    }
    market {
      id
      name
    }
  }
`);

export function PortfolioPerformance({
  investmentId,
}: {
  investmentId: number;
}) {
  const client = useApolloClient();
  const investmentDetails = client.readFragment({
    id: `Investment:${investmentId}`,
    fragment: INVESTMENT_SUMMARY,
  });
  const { data } = useQuery(GET_PORTFOLIO_CONFIG, {
    variables: { id: investmentId },
  });

  let lagMonths = 0;

  let performanceLag: { month: number; year: number } | undefined = undefined;
  switch (data?.porfolioConfigByPortfolioId?.performanceLag?.lagUnit.id) {
    case 3:
      lagMonths = data?.porfolioConfigByPortfolioId?.performanceLag?.lag * 1;
      break;
    case 4:
      lagMonths = data?.porfolioConfigByPortfolioId?.performanceLag?.lag * 3;
      break;
    case 5:
      lagMonths = data?.porfolioConfigByPortfolioId?.performanceLag?.lag * 12;
      break;
  }
  if (lagMonths) {
    const endDate = subMonths(new Date(), lagMonths);

    performanceLag = {
      month: endDate.getMonth(),
      year: endDate.getFullYear(),
    };
  }

  return (
    <>
      <div className="heading" data-cy="performance-heading">
        {I18n.t("overview.performance")}
      </div>
      {investmentDetails?.market?.id === 1 && (
        <RelativePerformance
          investmentId={investmentId}
          performanceLag={performanceLag}
        >
          <RelativePerformance.StatsChart />
          <RelativePerformance.MonthlyReturnsChart />
        </RelativePerformance>
      )}
      {investmentDetails?.market?.id === 2 && (
        <Irr investmentId={investmentId} />
      )}
    </>
  );
}
