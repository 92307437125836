import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { Checkbox, DatePicker as AntdDatePicker, TableColumnsType } from "antd";
import momentConfig from "rc-picker/lib/generate/moment";
import InvestmentLink from "Components/InvestmentLink";
import { parseISO, startOfMonth } from "date-fns";
import { formatCurrency } from "Helpers/index";
import i18n from "i18next";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ExpandableStatisticsTable from "./ExpandableStatisticsTable";
import {
  ALLOCATION_BALANCE_DATES,
  FETCH_ACCOUNT_MANAGER_DATA,
  FETCH_PORTFOLIO_BALANCES,
  FETCH_PORTFOLIO_DATA,
} from "./graphql";

const DatePicker = AntdDatePicker.generatePicker(momentConfig);

const ACCOUNTING_MANAGER_ORDER = [
  { tag: "Cash", order: 1 },
  { tag: "Liabilities", order: 2 },
  { tag: "Futures", order: 3 },
  { tag: "Futures Cogency", order: 4 },
  { tag: "Short Term Receivables", order: 5 },
  { tag: "Receivables", order: 6 },
  { tag: "Restricted Cash", order: 7 },
  { tag: "Inflows", order: 8 },
  { tag: "Outflows", order: 9 },
];

export type AssetAllocationItem = {
  key: string;
  itemNumber?: number;
  id?: number;
  name: string;
  strategyCode?: string;
  tag?: string;
  tag2?: string;
  tag3?: string;
  tag4?: string;
  isLinked?: boolean;
  endAllocation?: number;
  weight?: number;
  total?: number;
};

export type AssetAllocationItemsByType = {
  [key: string]: AssetAllocationItem[];
};

const setItemWeights = (rowData: AssetAllocationItemsByType) => {
  const total = Object.values(rowData).reduce(
    (acc, items) =>
      acc + items.reduce((acc2, item) => acc2 + (item.endAllocation || 0), 0),
    0
  );
  Object.values(rowData).forEach(items =>
    items.forEach(item => {
      item.weight = (item.endAllocation || 0) / total || 0;
    })
  );
};

const getAssetAllocationRows = (
  balancesList?: types.GetPortfolioBalancesQuery["balancesList"]["items"],
  accountingManagerList?: types.GetAccountingManagerDataQuery["accountingManagerList"]["items"]
): AssetAllocationItemsByType => {
  if (!balancesList && !accountingManagerList) return {};
  const rowData: AssetAllocationItemsByType = {};

  const accountingManagerIds = accountingManagerList?.map(
    manager => manager.id
  );

  const accountingAllocationRows: AssetAllocationItem[] = [];

  balancesList?.forEach(balance => {
    const { managerId, manager } = balance;
    if (accountingManagerIds?.includes(managerId)) {
      const managerData = accountingManagerList?.find(
        accountingManager => accountingManager.id === managerId
      );
      accountingAllocationRows.push({
        key: managerId.toString(),
        id: managerId,
        name: managerData?.name || "",
        endAllocation: balance.endBalance,
      });
    } else {
      const tag2 =
        manager?.managerTags?.items[0]?.tag2 || "Other/Not Available";
      if (!rowData[tag2]) {
        rowData[tag2] = [];
      }
      rowData[tag2].push({
        key: manager?.id.toString() || managerId.toString(),
        id: manager?.id,
        name: manager?.name || `Manager ${managerId}`,
        strategyCode: manager?.strategy?.code,
        tag: manager?.managerTags?.items[0]?.tag,
        tag2: manager?.managerTags?.items[0]?.tag2 || "",
        tag3: manager?.managerTags?.items[0]?.tag3 || "",
        tag4: manager?.managerTags?.items[0]?.tag4 || "",
        isLinked: manager?.vPortfolioManagers?.[0]?.isLinked || false,
        endAllocation: balance?.endBalance,
      });
    }
  });

  accountingManagerList?.forEach(manager => {
    if (accountingAllocationRows.some(row => row.id === manager.id)) return;
    accountingAllocationRows.push({
      key: manager.id.toString(),
      id: manager.id,
      name: manager.name,
      endAllocation: 0,
    });
  });

  // sort groups alphabetically, but keep accounting at the end
  const sortedRowData = Object.keys(rowData)
    .sort((a, b) => (a > b ? 1 : -1))
    .reduce((acc, key) => {
      if (key === "Accounting") {
        return acc;
      }
      acc[key] = rowData[key];
      return acc;
    }, {} as AssetAllocationItemsByType);

  // set item numbers
  let count = 1;
  Object.entries(sortedRowData).forEach(([key, value]) => {
    // sort alphabetically by name
    sortedRowData[key] = value.sort((a, b) => (a.name > b.name ? 1 : -1));
    value.forEach(item => {
      item.itemNumber = count;
      count += 1;
    });
  });

  sortedRowData.Accounting = accountingAllocationRows.sort((a, b) => {
    const aOrder =
      ACCOUNTING_MANAGER_ORDER.find(order => order.tag === a.name)?.order || 0;
    const bOrder =
      ACCOUNTING_MANAGER_ORDER.find(order => order.tag === b.name)?.order || 0;
    return aOrder > bOrder ? 1 : -1;
  });

  setItemWeights(sortedRowData);

  return sortedRowData;
};

/* eslint-disable-next-line max-lines-per-function */
function AssetAllocationTable({
  investmentId,
  isRockCreekUser = false,
}: {
  investmentId: number;
  isRockCreekUser?: boolean;
}): JSX.Element {
  const [date, setDate] = useState<Date | null>(null);
  const { data: portfolioData } = useQuery(FETCH_PORTFOLIO_DATA, {
    variables: {
      filter: {
        investmentIds: [investmentId],
        isPrimary: true,
      },
    },
  });

  const portfolioId = portfolioData?.portfolioList?.items[0]?.id || 0;

  const { loading, error, data } = useQuery(FETCH_PORTFOLIO_BALANCES, {
    variables: {
      portfolioId,
      startDate: date?.toISOString().slice(0, 10),
      endDate: date?.toISOString().slice(0, 10),
    },
    skip: !date || !portfolioId,
  });

  const { data: accountingManagerData, loading: accountingManagerLoading } =
    useQuery(FETCH_ACCOUNT_MANAGER_DATA);

  const { data: datesData, loading: datesLoading } = useQuery(
    ALLOCATION_BALANCE_DATES,
    {
      variables: {
        portfolioId,
      },
      skip: !portfolioId,
    }
  );

  useEffect(() => {
    if (datesData && datesData.balanceDates.length > 0) {
      const currentDate = startOfMonth(new Date()).toISOString().slice(0, 10);
      const newDate = new Date(
        datesData.balanceDates.find(dateVal => dateVal <= currentDate)
      );
      setDate(newDate);
    }
  }, [datesData]);

  const managerAllocationData = useMemo(
    () =>
      getAssetAllocationRows(
        data?.balancesList?.items,
        accountingManagerData?.accountingManagerList?.items
      ),
    [data, accountingManagerData]
  );

  const updateDate = (newDate: moment.Moment | null) => {
    if (!newDate) return;
    const monthStart = startOfMonth(newDate.toDate());
    setDate(monthStart);
  };

  const disabledTime = useCallback(
    (selectionDate: Moment) =>
      !datesData?.balanceDates
        .map(dateDatum =>
          parseISO(new Date(dateDatum).toISOString().slice(0, 10))
        )
        .some(
          (dateDatum: Date) =>
            selectionDate.month() === dateDatum.getMonth() &&
            selectionDate.year() === dateDatum.getFullYear()
        ),
    [datesData]
  );

  const colHeaders: TableColumnsType<AssetAllocationItem> = [
    {
      title: "",
      dataIndex: "itemNumber",
      key: "itemNumber",
      width: 2,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 8,
      align: "left",
      fixed: true,
      render: (name, record) => {
        const { id, isEntitled } =
          data?.balancesList?.items.find(item => item.managerId === record.id)
            ?.manager || {};
        return id && isEntitled ? (
          <InvestmentLink
            className="fund-link-wrapper"
            investmentId={id}
            isEntitled={isEntitled}
          >
            <p style={{ wordBreak: "break-word" }}>{name}</p>
          </InvestmentLink>
        ) : (
          <p>{name}</p>
        );
      },
    },
    {
      title: "Firm",
      dataIndex: "strategyCode",
      key: "strategyCode",
      width: 1.5,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Client",
      dataIndex: "tag",
      key: "tag",
      width: 3,
      align: "left",
      fixed: true,
    },
    {
      title: "Team",
      dataIndex: "tag2",
      key: "tag2",
      width: 3,
      align: "left",
      fixed: true,
    },
  ];

  // hide open & guideline tags from external users
  if (isRockCreekUser) {
    colHeaders.push(
      {
        title: "Open",
        dataIndex: "tag3",
        key: "tag3",
        width: 3,
        align: "left",
        fixed: true,
      },
      {
        title: "Guideline",
        dataIndex: "tag4",
        key: "tag4",
        width: 3,
        align: "left",
        fixed: true,
      }
    );
  }

  colHeaders.push(
    {
      title: "Link",
      dataIndex: "isLinked",
      key: "isLinked",
      width: 1,
      align: "center",
      fixed: true,
      render: isLinked => <Checkbox checked={isLinked} disabled />,
    },
    {
      title: "End Allocation",
      dataIndex: "endAllocation",
      key: "endAllocation",
      width: 4,
      align: "left",
      ellipsis: true,
      render: endAllocation => (
        <p
          style={{
            color: endAllocation < 0 ? "red" : undefined,
            wordBreak: "break-word",
          }}
        >
          {endAllocation < 0
            ? `(${formatCurrency(Math.abs(endAllocation).toFixed(0))})`
            : `${formatCurrency(endAllocation.toFixed(0))}`}
        </p>
      ),
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: 3,
      align: "left",
      ellipsis: true,
      render: weight => (
        <p
          style={{
            color: weight < 0 ? "red" : undefined,
            wordBreak: "break-word",
          }}
        >
          {(weight * 100).toFixed(2)}%
        </p>
      ),
    },
    {
      title: "#",
      dataIndex: "total",
      key: "total",
      width: 2,
      align: "left",
      ellipsis: true,
    }
  );

  if (error) {
    return (
      <span>
        Something went wrong fetching allocation data
        <span hidden>{JSON.stringify(error, null, 2)}</span>
      </span>
    );
  }

  return (
    <div>
      {error && (
        <div className="error">
          Something went wrong, please contact support.
          {JSON.stringify(error, null, 2)}
        </div>
      )}
      <div className="summary-heading" data-cy="assetAllocation">
        <h3 className="invt-tab__title" id="assetAllocation">
          {i18n.t("overview.portfolio")}
        </h3>

        <div className="summary-heading__desc-wrap">
          {datesLoading && (
            <i
              data-testid="loading-spinner"
              className="fa fa-spinner fa-spin"
            />
          )}
          {date && (
            <>
              <span className="summary-heading__desc-label">
                {i18n.t("date.as_of", { date: "" })}
              </span>

              <div className="rc-calendar-custom-wrap width-120">
                <DatePicker
                  picker="month"
                  format="MMM yyyy"
                  disabledDate={disabledTime}
                  allowClear={false}
                  placeholder="Set the date"
                  defaultValue={moment(date.toISOString().substring(0, 10))}
                  onChange={updateDate}
                  data-cy="month-picker"
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        <div id="assetAllocationTable" className="table-responsive">
          <ExpandableStatisticsTable
            columnHeaders={colHeaders}
            data={managerAllocationData}
            loading={loading || accountingManagerLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default AssetAllocationTable;
