import {
  ApolloQueryResult,
  useApolloClient,
  useLazyQuery,
  useQuery,
} from "@apollo/client";
import { displayErrorFlash } from "_sirius/src/actions/action_helpers";
import { Collapse, Row } from "antd";
import { Layout } from "Components/layoutswitcher";
import { useCurrentUser } from "frontend/src/utils/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DocumentCountByDocumentType,
  DocumentCountByDocumentTypeSortEnum,
  DocumentSearchResultsQuery,
  DocumentSortEnum,
  SortInput,
} from "src/graphql-types/graphql";
import { useDebouncedCallback } from "use-debounce";
import { Spinner } from "../spinner.js";
import DocumentComponent from "./document";
import DocumentsChat from "./documentsChat";
import { BULK_DOWNLOAD, DOCUMENTS_SEARCH, DOCUMENT_ENUMS } from "./graphql";
import {
  BulkDownloadDrawer,
  SearchByDate,
  SearchByExtensions,
  SearchByName,
  SearchWithinText,
} from "./sharedComponents";
import { DocumentCategoryViewProps } from "./types";

type DocumentResult = DocumentSearchResultsQuery["documents"]["items"][number];

const PanelBulkSelect = ({
  bulkIds,
  addToBulk,
  docIds,
  removeFromBulk,
}: {
  bulkIds: number[];
  addToBulk: (id: number | number[]) => void;
  removeFromBulk: (id: number | number[]) => void;
  docIds: number[];
}) => {
  return (
    <input
      type="checkbox"
      className="doc-list__add-to-bulk"
      onChange={e => {
        const { checked } = e.target;
        checked ? addToBulk(docIds) : removeFromBulk(docIds);
      }}
      checked={!!docIds.length && docIds.every(id => bulkIds.includes(id))}
    />
  );
};

const CategorySection = ({
  docCountByType,
  getDocsByYear,
  addToBulk,
  removeFromBulk,
  bulkIds,
  setSpecificChatDocumentId,
}: {
  docCountByType: {
    id: number;
    name: string;
    count: number;
    years: DocumentCountByDocumentType[];
  };
  getDocsByYear: (
    docTypeId: number,
    year: number
  ) => Promise<ApolloQueryResult<DocumentSearchResultsQuery>>;
  addToBulk: (id: number | number[]) => void;
  removeFromBulk: (id: number | number[]) => void;
  bulkIds: number[];
  setSpecificChatDocumentId?: (id: number) => void;
}) => {
  const [docsByYear, setDocsByYear] = useState(
    new Map<number, DocumentResult[]>()
  );
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  useEffect(() => {
    setDocsByYear(new Map());
    setActiveKeys([String(docCountByType.years[0].year!)]);
  }, [docCountByType, getDocsByYear]);

  useEffect(() => {
    for (const activeKey of activeKeys) {
      const year = Number(activeKey);
      if (!docsByYear.has(year)) {
        getDocsByYear(docCountByType.id, year).then(({ data }) => {
          setDocsByYear(
            docsByYear =>
              new Map([
                ...docsByYear,
                [year, data.documents.items.filter(item => item.signedUrl)],
              ])
          );
        });
      }
    }
  }, [activeKeys]);

  return (
    <>
      <h2
        data-cy="documents-view_docTypeName"
        className="invt-tab__title margin-t-sm"
      >
        {docCountByType.name} {`\xa0`} ({docCountByType.count})
      </h2>
      <Collapse
        activeKey={activeKeys}
        onChange={keys => {
          if (!Array.isArray(keys)) {
            throw Error("expected array of active keys");
          }
          setActiveKeys(keys);
        }}
      >
        {docCountByType.years.map(year => (
          <Collapse.Panel
            header={`${year.year!} \xa0 (${year.count})`}
            key={year.year!}
            collapsible="header"
            extra={
              <PanelBulkSelect
                docIds={docsByYear.get(year.year!)?.map(doc => doc.id) ?? []}
                addToBulk={addToBulk}
                removeFromBulk={removeFromBulk}
                bulkIds={bulkIds}
              />
            }
          >
            <ul>
              {docsByYear.get(year.year!) ? null : <Spinner />}
              {docsByYear.get(year.year!)?.map(doc => (
                <li key={doc.id}>
                  <DocumentComponent
                    doc={doc}
                    layout={Layout.category}
                    addToBulk={addToBulk}
                    removeFromBulk={removeFromBulk}
                    bulkIds={bulkIds}
                    setSpecificChatDocumentId={setSpecificChatDocumentId}
                  />
                </li>
              ))}
            </ul>
          </Collapse.Panel>
        ))}
      </Collapse>
    </>
  );
};

export function CategoryView({
  categoryId,
  companyId,
  dealId,
  companyOrDealId,
  firmId,
  investmentOrFirmId,
  investmentId,
  showChat,
  clientDocFilters,
}: DocumentCategoryViewProps) {
  const { flags } = useCurrentUser();
  const [bulkIds, setBulkIds] = useState<number[]>([]);
  const [name, setName] = useState("");
  const debouncedName = useDebouncedCallback((_name: string) => {
    setName(_name);
  }, 300);
  const [extensions, setExtensions] = useState<string[]>();
  const debouncedExtensions = useDebouncedCallback((_extensions: string[]) => {
    setExtensions(_extensions.length ? _extensions : undefined);
  }, 300);
  const [text, setText] = useState("");
  const debouncedText = useDebouncedCallback((_text: string) => {
    setText(_text);
  }, 300);
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();
  const [specificChatDocumentId, setSpecificChatDocumentId] = useState<
    number | null
  >(null);

  const filter = {
    investmentId: !investmentOrFirmId ? investmentId : void 0,
    firmId: !investmentOrFirmId ? firmId : void 0,
    dealId: !companyOrDealId ? dealId : void 0,
    companyId: !companyOrDealId ? companyId : void 0,
    documentCategoryEnumId: categoryId,
    investmentOrFirmId,
    companyOrDealId,
    name,
    extensions,
    text,
    minDate,
    maxDate,
    accessLevel: clientDocFilters?.accessLevel,
  };

  const { loading, error, data } = useQuery(DOCUMENT_ENUMS, {
    fetchPolicy: "no-cache",
    variables: {
      includeYear: true,
      filter,
      sort: [
        {
          field: DocumentCountByDocumentTypeSortEnum.Name,
          order: SortInput.Asc,
        },
        {
          field: DocumentCountByDocumentTypeSortEnum.Year,
          order: SortInput.Desc,
        },
      ],
    },
  });

  const apolloClient = useApolloClient();

  const getDocsByYear = useCallback(
    (docTypeId: number, year: number) => {
      const minDates = [Date.UTC(year, 0, 1)];
      if (filter.minDate) minDates.push(filter.minDate.getTime());
      const minDate = new Date(Math.max(...minDates));

      const maxDates = [Date.UTC(year + 1, 0, 1, 0, 0, -1)];
      if (filter.maxDate) maxDates.push(filter.maxDate.getTime());
      const maxDate = new Date(Math.min(...maxDates));

      return apolloClient.query({
        fetchPolicy: "no-cache",
        query: DOCUMENTS_SEARCH,
        variables: {
          investmentId: filter.investmentId,
          firmId: filter.firmId,
          dealId: filter.dealId,
          companyId: filter.companyId,
          investmentOrFirmId: filter.investmentOrFirmId,
          companyOrDealId: filter.companyOrDealId,
          categoryId: filter.documentCategoryEnumId,
          typeId: docTypeId,
          name: filter.name,
          extensions: filter.extensions,
          text: filter.text,
          minDate,
          maxDate,
          accessLevel: filter.accessLevel,
          sort: {
            field: DocumentSortEnum.Date,
            order: SortInput.Desc,
          },
        },
      });
    },
    [apolloClient, JSON.stringify(filter)]
  );

  const addToBulk = (id: number | number[]) => {
    if (Array.isArray(id)) {
      const newIds = bulkIds.concat(id);
      setBulkIds(newIds);
    } else {
      setBulkIds([...bulkIds, id]);
    }
  };

  const removeFromBulk = (id: number | number[]) => {
    if (Array.isArray(id)) {
      setBulkIds(bulkIds.filter(n => !id.includes(n)));
    } else {
      setBulkIds(bulkIds.filter(n => n !== id));
    }
  };

  const [bulkDownload, { loading: bulkLoading, data: bulkData }] = useLazyQuery(
    BULK_DOWNLOAD,
    {
      onCompleted: () => {
        if (bulkData?.documentDownloadBatch.url) {
          window.location.assign(bulkData.documentDownloadBatch.url);
        }
        setBulkIds([]);
      },
    }
  );

  const documentCountByDocumentType = useMemo(() => {
    const map = (data?.documentCountByDocumentType ?? []).reduce((map, x) => {
      let item = map.get(x.id);
      if (!item) {
        map.set(x.id, (item = { id: x.id, name: x.name, count: 0, years: [] }));
      }
      item.count += x.count;
      item.years.push(x);
      return map;
    }, new Map<number, { id: number; name: string; count: number; years: DocumentCountByDocumentType[] }>());
    return [...map.values()];
  }, [data]);

  if (error) displayErrorFlash();
  return (
    <>
      <div className="sticky-container sticky-container--static">
        {investmentId && showChat && flags.isRockCreek && (
          <Collapse>
            <Collapse.Panel
              header={
                <>
                  <img
                    style={{ width: "35px", height: "35px" }}
                    src="/assets/images/docchatIcon.png"
                  />
                  Document Chat
                </>
              }
              key="1"
            >
              <DocumentsChat
                investmentId={investmentId}
                specificDocumentId={specificChatDocumentId}
                setSpecificChatDocumentId={setSpecificChatDocumentId}
              />
            </Collapse.Panel>
          </Collapse>
        )}
        <div className="sticky-container__content__no-sidebar">
          <BulkDownloadDrawer
            bulkDownload={bulkDownload}
            bulkIds={bulkIds}
            bulkLoading={bulkLoading}
            setBulkIds={setBulkIds}
          />
          <div className="text-left">
            <Row>
              <SearchWithinText debouncedText={debouncedText} />
            </Row>
            <Row style={{ marginTop: 8 }} gutter={8}>
              <SearchByName debouncedName={debouncedName} />
              <SearchByExtensions debouncedExtensions={debouncedExtensions} />
              <SearchByDate setMinDate={setMinDate} setMaxDate={setMaxDate} />
            </Row>
          </div>
        </div>
      </div>
      <div className="overview-page__top-info">
        <div className="invt-tab__section">
          {loading ? (
            <Spinner />
          ) : (
            documentCountByDocumentType.map(docCountByType => (
              <CategorySection
                key={docCountByType.id}
                docCountByType={docCountByType}
                getDocsByYear={getDocsByYear}
                addToBulk={addToBulk}
                removeFromBulk={removeFromBulk}
                bulkIds={bulkIds}
                setSpecificChatDocumentId={setSpecificChatDocumentId}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
}
