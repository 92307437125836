import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { SortInput } from "_graphql-types/graphql";
import { isEqual, omit } from "lodash";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FIELDS_SEARCH } from "./graphql";
import {
  FieldsIndexContextInterface,
  LogicalOrFilters,
  isLogicalOrFilterKey,
  isFieldFilterObject,
} from "./types";
import { ensureDefined } from "frontend/src/utils/helpers";

export const modifyDateSort = [
  {
    field: types.FieldSortEnum.ModifyDate,
    order: SortInput.Desc,
  },
];

export const textRelevancySort = [
  {
    field: types.FieldSortEnum.TextSearchRelevance,
    order: SortInput.Desc,
  },
  {
    field: types.FieldSortEnum.ModifyDate,
    order: SortInput.Desc,
  },
];

// eslint-disable-next-line max-lines-per-function
export function useFieldsIndex({
  fieldsFilters,
}: {
  fieldsFilters?: types.FieldFilter;
}): FieldsIndexContextInterface {
  const [fieldsFiltersState, setFieldsFiltersState] = useState<
    types.FieldFilter | undefined
  >(undefined);

  const [fieldsSort, setFieldsSort] =
    useState<types.FieldSort[]>(modifyDateSort);

  // note filters from outer context take precedence
  const filters = useMemo(
    () => ({ ...fieldsFiltersState, ...fieldsFilters }),
    [fieldsFilters, fieldsFiltersState]
  );

  const { data, loading, error, fetchMore } = useQuery(FIELDS_SEARCH, {
    variables: {
      filters,
      sort: fieldsSort,
      page: {
        offset: 0,
        limit: 15,
      },
    },
  });

  const [loadingMore, setLoadingMore] = useState(false);

  const { items: fields, nextPage } = useMemo(
    () =>
      data?.fieldList ?? {
        items: [] as types.FetchFieldsListQuery["fieldList"]["items"],
        nextPage: { offset: 0, limit: 0, hasMore: false },
      },
    [data]
  );

  const loadMoreFields = useCallback(() => {
    setLoadingMore(true);
    fetchMore({
      variables: {
        page: {
          limit: nextPage?.limit,
          offset: (nextPage?.offset ?? 0) + (nextPage?.limit ?? 15),
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const newFields = fetchMoreResult?.fieldList.items;
        const oldFields = prev ? prev.fieldList.items : [];
        // https://github.com/apollographql/apollo-client/issues/5897
        return {
          fieldList: {
            ...fetchMoreResult.fieldList,
            items: [...oldFields, ...newFields],
          },
        };
      },
    }).then(() => {
      setLoadingMore(false);
    });
  }, [fetchMore, nextPage, setLoadingMore]);

  /**
   * Updates the field filter state and handles logical OR filters
   * @param value either a field filter object or the value to be added to a logical OR filter array
   * @param filterKey only used for logical OR filters
   */
  function updateFilter(
    value:
      | types.FieldFilter
      | NonNullable<LogicalOrFilters[keyof LogicalOrFilters]>[number],
    filterKey?: keyof LogicalOrFilters
  ) {
    if (isFieldFilterObject(value)) {
      setFieldsFiltersState(value);
    } else {
      setFieldsFiltersState(
        addOrFilter(
          ensureDefined(
            filterKey,
            "cannot update logical OR filter without key"
          ),
          value
        )
      );
    }
  }

  /**
   * Removes a field filter from filter state, or a single value from a logical OR filter array
   * @param filterKey
   * @param value only used for logical OR filters (value to be removed from array)
   */
  function removeFilter(
    filterKey: keyof types.FieldFilter,
    value?: NonNullable<LogicalOrFilters[keyof LogicalOrFilters]>[number]
  ) {
    if (isLogicalOrFilterKey(filterKey)) {
      setFieldsFiltersState(
        removeOrFilter(
          filterKey,
          ensureDefined(
            value,
            "value is required for removing logical OR filters"
          )
        )
      );
    } else {
      setFieldsFiltersState(omit(filters, filterKey));
    }
  }

  function addOrFilter(
    filterKey: keyof LogicalOrFilters,
    value: NonNullable<LogicalOrFilters[keyof LogicalOrFilters]>[number]
  ) {
    const orFilters = filters[filterKey] || [];
    return {
      ...filters,
      [filterKey]: [...orFilters, value],
    };
  }

  function removeOrFilter(
    filterKey: keyof LogicalOrFilters,

    value: NonNullable<LogicalOrFilters[keyof LogicalOrFilters]>[number]
  ) {
    const orFilters = filters[filterKey] || [];
    return {
      ...filters,
      [filterKey]: orFilters.filter(f => !isEqual(f, value)),
    };
  }

  const contextValue = useMemo(
    () => ({
      fields,
      loading: loading || loadingMore,
      error,
      fieldsFilters: filters,
      fieldsSort,
      fetchMore: loadMoreFields,
      updateFieldsFilter: updateFilter,
      removeFieldsFilter: removeFilter,
      updateFieldsSort: setFieldsSort,
      nextPage,
    }),
    [
      fields,
      loading,
      loadingMore,
      error,
      filters,
      fieldsSort,
      nextPage,
      loadMoreFields,
      updateFilter,
      removeFilter,
      setFieldsSort,
    ]
  );

  return contextValue;
}

export const fieldsIndexContext = createContext<FieldsIndexContextInterface>({
  fields: undefined,
  error: undefined,
  loading: false,
  investmentFilters: undefined,
  fieldsFilters: undefined,
  nextPage: undefined,
  fieldsSort: modifyDateSort,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchMore: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateFieldsFilter: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeFieldsFilter: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateFieldsSort: () => {},
});
