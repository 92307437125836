/* eslint-disable max-lines-per-function */
import React from "react";
import { Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobileView } from "Helpers/window_wrapper";
import InvestmentPositionsTable from "Components/InvestmentPositionsTable";
import { PeersTable } from "Components/show/analytics/PeersTable.component";
import i18n from "i18next";
import InvestmentSummaryTile from "Components/show/overview/InvestmentSummaryTile";
import SummaryFundAdvisor from "Components/show/overview/SummaryFundAdvisor";
import Classifications from "Components/show/overview/Classifications";
import Status from "Components/show/overview/Status";
import InvestmentFirmTile from "Components/show/overview/InvestmentFirmTile";
import SummaryFundTile from "Components/show/overview/SummaryFundTile";
import SummaryContactTile from "Components/show/overview/SummaryContactTile";
import LiquidationTile from "Components/show/overview/LiquidationTile";
import Performance from "Components/Performance";
import InvestmentServiceProviders from "Components/show/overview/InvestmentServiceProviders";
import InvestmentRatingsNav from "frontend/src/components/InvestmentRatingsNav";
import { HfInvestmentTerms } from "../../../InvestmentTerms";

interface Props {
  investmentId: number;
}

const { TabPane } = Tabs;

const PublicInvestmentOverview = ({ investmentId }: Props): JSX.Element => {
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();

  const subtabRoute = (subtab: string) => {
    navigate(`${pathname}#${subtab}`);
  };

  return (
    <div className="main-content main-content--lg">
      <Tabs
        activeKey={!hash ? "general" : hash.replace("#", "")}
        data-cy="public-investment-tabs"
        onChange={subtabRoute}
      >
        <TabPane tab={i18n.t("overview.general")} key="general">
          <div className="overview-page__top-info">
            <div className="overview-page__top-info-left">
              <InvestmentSummaryTile investmentId={investmentId} />

              <div className="invt-tab__section hidden-md-up">
                <SummaryFundAdvisor
                  investmentId={investmentId}
                  className="fund-advisor--mobile"
                />
              </div>

              <Classifications investmentId={investmentId} />
              <Status investmentId={investmentId} />
              <LiquidationTile investmentId={investmentId} />

              <div className="d-flex flex-wrap">
                <InvestmentFirmTile investmentId={investmentId} />

                <SummaryFundTile investmentId={investmentId} />
              </div>

              <div className="invt-tab__section">
                <SummaryContactTile investmentId={investmentId} />
              </div>
            </div>
            <div className="overview-page__top-info-right hidden-md-down">
              <SummaryFundAdvisor investmentId={investmentId} />

              {!isMobileView() && (
                <div id="fund-ratings">
                  <InvestmentRatingsNav investmentId={investmentId} />
                </div>
              )}
            </div>
          </div>
        </TabPane>
        <TabPane tab={i18n.t("overview.performance")} key="performance">
          <Performance investmentId={investmentId} />
        </TabPane>
        <TabPane tab={i18n.t("overview.peers")} key="peers">
          <div className="invt-tab__section" style={{ maxWidth: "1500px" }}>
            <PeersTable investmentId={investmentId} />
          </div>
        </TabPane>
        <TabPane tab={i18n.t("overview.terms")} key="terms">
          <div className="invt-tab__section">
            <HfInvestmentTerms investmentId={investmentId} />
          </div>
        </TabPane>
        <TabPane tab={i18n.t("overview.positions")} key="positions">
          <div className="invt-tab__section">
            <InvestmentPositionsTable investmentId={investmentId} />
          </div>
        </TabPane>
        <TabPane
          tab={i18n.t("overview.service_providers")}
          key="service_providers"
        >
          <div className="invt-tab__section">
            <InvestmentServiceProviders investmentId={investmentId} />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PublicInvestmentOverview;
