/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useLazyQuery, useQuery } from "@apollo/client";
import { Badge, Col, Collapse, Dropdown, Menu, Row } from "antd";
import { InfiniteScroller } from "Components/InfiniteScroller";
import { Layout, LayoutSwitcher } from "Components/layoutswitcher";
/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import {
  DocumentCountByDocumentType,
  DocumentCountByDocumentTypeSort,
  DocumentCountByDocumentTypeSortEnum,
  DocumentFilter,
  DocumentSort,
  DocumentSortEnum,
  SortInput,
} from "_graphql-types/graphql";
import DocumentComponent from "./document";
import { BULK_DOWNLOAD, DOCUMENTS_SEARCH, DOCUMENT_ENUMS } from "./graphql";
import LeftSidebarWrapper from "./left_sidebar_wrapper.js.jsx";
import { DocumentSearchViewProps } from "./types";
import {
  DocumentSortDropdown,
  BulkDownloadDrawer,
  SearchByDate,
  SearchByName,
  SearchWithinText,
  SearchByExtensions,
} from "./sharedComponents";
import DocumentsChat from "./documentsChat";
import { useCurrentUser } from "frontend/src/utils/hooks";

const { Panel } = Collapse;

const SideBar = ({
  changeEnum,
  enumId,
  documentId,
  filter,
}: {
  changeEnum: (id: number | undefined) => void;
  enumId: number | undefined;
  documentId: number | undefined;
  filter: DocumentFilter;
}) => {
  const sortOptions = {
    [DocumentCountByDocumentTypeSortEnum.Name]: {
      [SortInput.Asc]: "Name A-Z",
      [SortInput.Desc]: "Name Z-A",
    },
    [DocumentCountByDocumentTypeSortEnum.Count]: {
      [SortInput.Asc]: "Least Documents",
      [SortInput.Desc]: "Most Documents",
    },
  };

  const [sort, setSort] = useState<DocumentCountByDocumentTypeSort>({
    field: DocumentCountByDocumentTypeSortEnum.Name,
    order: SortInput.Asc,
  });

  const { data } = useQuery<{
    documentCountByDocumentType: DocumentCountByDocumentType[];
  }>(DOCUMENT_ENUMS, {
    variables: { filter, sort: [sort] },
  });

  const documentCountByDocumentType = useMemo(
    () => data?.documentCountByDocumentType ?? [],
    [data]
  );

  return (
    <LeftSidebarWrapper>
      <Dropdown
        overlay={
          <Menu
            onClick={({ key }: { key: string }) => {
              const [field, order] = key.split(",", 2);
              setSort({
                field: field as DocumentCountByDocumentTypeSortEnum,
                order: order as SortInput,
              });
            }}
            selectedKeys={[`${sort.field},${sort.order}`]}
          >
            <Menu.Item
              key={`${DocumentCountByDocumentTypeSortEnum.Name},${SortInput.Asc}`}
            >
              {
                sortOptions[DocumentCountByDocumentTypeSortEnum.Name][
                  SortInput.Asc
                ]
              }
            </Menu.Item>
            <Menu.Item
              key={`${DocumentCountByDocumentTypeSortEnum.Name},${SortInput.Desc}`}
            >
              {
                sortOptions[DocumentCountByDocumentTypeSortEnum.Name][
                  SortInput.Desc
                ]
              }
            </Menu.Item>
            <Menu.Item
              key={`${DocumentCountByDocumentTypeSortEnum.Count},${SortInput.Asc}`}
            >
              {
                sortOptions[DocumentCountByDocumentTypeSortEnum.Count][
                  SortInput.Asc
                ]
              }
            </Menu.Item>
            <Menu.Item
              key={`${DocumentCountByDocumentTypeSortEnum.Count},${SortInput.Desc}`}
            >
              {
                sortOptions[DocumentCountByDocumentTypeSortEnum.Count][
                  SortInput.Desc
                ]
              }
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#">
          {sort &&
            (
              sortOptions as Record<
                DocumentCountByDocumentTypeSortEnum,
                Record<SortInput, string>
              >
            )[sort.field][sort.order]}{" "}
          <i className="main-dropdown__arrow icon icon-arrow" />
        </a>
      </Dropdown>
      <ul>
        <li
          onClick={() => changeEnum(undefined)}
          className={`add-page-nav__item  document-folders__item  ${
            !enumId && !documentId ? "active" : ""
          }`}
        >
          All
        </li>
        {documentCountByDocumentType.map(({ id, name, count }) => (
          <li
            onClick={() => changeEnum(id)}
            className={`add-page-nav__item document-folders__item  ${
              id === enumId ? "active" : ""
            }`}
            key={`documentEnum_${id}`}
          >
            {name}
            <Badge count={count} style={{ backgroundColor: "#1890ff" }} />
          </li>
        ))}
      </ul>
    </LeftSidebarWrapper>
  );
};

const en = {
  [DocumentSortEnum.Date]: {
    [SortInput.Desc]: "Newest",
    [SortInput.Asc]: "Oldest",
  },
  [DocumentSortEnum.Name]: {
    [SortInput.Asc]: "Name A-Z",
    [SortInput.Desc]: "Name Z-A",
  },
  [DocumentSortEnum.DocumentTypeEnumName]: {
    [SortInput.Asc]: "Document Type A-Z",
    [SortInput.Desc]: "Document Type Z-A",
  },
  [DocumentSortEnum.TextSearchRank]: {
    [SortInput.Asc]: "Text Search",
    [SortInput.Desc]: "",
  },
  [DocumentSortEnum.NameSearchRank]: {
    [SortInput.Asc]: "Name Search",
    [SortInput.Desc]: "",
  },
};

export const DocumentsPage = ({
  investmentId,
  firmId,
  companyId,
  dealId,
  investmentFilters,
  firmFilters,
  companyFilters,
  investmentOrFirmId,
  companyOrDealId,
  showChat = false,
  clientDocFilters,
}: DocumentSearchViewProps): JSX.Element => {
  const [typeId, setTypeId] = useState<number>();
  const [name, setName] = useState("");
  const debouncedName = useDebouncedCallback((_name: string) => {
    setName(_name);
  }, 300);
  const [extensions, setExtensions] = useState<string[]>();
  const debouncedExtensions = useDebouncedCallback((_extensions: string[]) => {
    setExtensions(_extensions.length ? _extensions : undefined);
  }, 300);
  const [text, setText] = useState("");
  const debouncedText = useDebouncedCallback((_text: string) => {
    setText(_text);
  }, 300);
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();
  const [sort, setSort] = useState<DocumentSort>({
    field: DocumentSortEnum.Date,
    order: SortInput.Desc,
  });
  const [bulkIds, setBulkIds] = useState<number[]>([]);
  const [layout, setLayout] = useState<Layout>(Layout.list);
  const [documentId, setDocumentId] = useState<number>();
  const [pannelOpen, setPannelOpen] = useState(false);
  const [specificChatDocumentId, setSpecificChatDocumentId] = useState<
    number | null
  >(null);

  const { flags } = useCurrentUser();

  // handle direct document link
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname.startsWith("/documents/")) {
      const id = parseInt(location.pathname.slice(11));
      if (Number.isFinite(id)) {
        setDocumentId(id);
        navigate("/documents");
      }
    }
  }, [location.pathname]);

  const addToBulk = (id: number) => {
    setBulkIds([...bulkIds, id]);
  };

  const removeFromBulk = (id: number) => {
    setBulkIds(bulkIds.filter(n => n !== id));
  };

  const { loading, error, data, fetchMore } = useQuery(DOCUMENTS_SEARCH, {
    variables: {
      investmentId: !investmentOrFirmId ? investmentId : undefined,
      firmId: !investmentOrFirmId ? firmId : undefined,
      dealId: !companyOrDealId ? dealId : undefined,
      companyId: !companyOrDealId ? companyId : undefined,
      categoryId: undefined,
      companyOrDealId,
      investmentOrFirmId,
      typeId,
      investmentFilters,
      firmFilters,
      companyFilters,
      page: { offset: 0, limit: 24 },
      name,
      extensions,
      text,
      minDate,
      maxDate,
      ids: typeof documentId === "number" ? [documentId] : undefined,
      sort,
      ...clientDocFilters,
    },
  });

  const [bulkDownload, { loading: bulkLoading, data: bulkData }] = useLazyQuery(
    BULK_DOWNLOAD,
    {
      onCompleted: () => {
        if (bulkData?.documentDownloadBatch.url) {
          window.location.assign(bulkData.documentDownloadBatch.url);
        }
        setBulkIds([]);
      },
    }
  );

  const loadMore = () => {
    if (!data?.documents?.nextPage?.hasMore) {
      return null;
    }
    return fetchMore({
      variables: {
        page: {
          limit: 24,
          offset: data.documents.items.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          documents: {
            ...fetchMoreResult.documents,
            items: [
              ...(prev.documents?.items ?? []),
              ...(fetchMoreResult.documents?.items ?? []),
            ],
          },
        };
      },
    });
  };

  const enumFilter = useMemo(
    () => ({
      investmentId: !investmentOrFirmId ? investmentId : undefined,
      firmId: !investmentOrFirmId ? firmId : undefined,
      investmentOrFirmId,
      investmentFilters,
      firmFilters,
      dealId: !companyOrDealId ? dealId : undefined,
      companyId: !companyOrDealId ? companyId : undefined,
      companyOrDealId,
      ...clientDocFilters,
    }),
    [investmentOrFirmId, investmentId, investmentFilters, firmFilters]
  );

  useEffect(() => {
    if (specificChatDocumentId) setPannelOpen(true);
  }, [specificChatDocumentId]);

  return (
    <div className="sticky-container sticky-container--static">
      {investmentId && showChat && flags.isRockCreek && (
        <Collapse
          activeKey={pannelOpen ? "1" : undefined}
          onChange={key => {
            setPannelOpen(!pannelOpen);
          }}
        >
          <Panel
            header={
              <>
                <img
                  style={{ width: "35px", height: "35px" }}
                  src="/assets/images/docchatIcon.png"
                />
                Document Chat
              </>
            }
            key="1"
          >
            <DocumentsChat
              investmentId={investmentId}
              specificDocumentId={specificChatDocumentId}
              setSpecificChatDocumentId={setSpecificChatDocumentId}
            />
          </Panel>
        </Collapse>
      )}
      <SideBar
        changeEnum={id => {
          setTypeId(id);
          setDocumentId(undefined);
        }}
        enumId={typeId}
        documentId={documentId}
        filter={enumFilter}
      />
      <div className="sticky-container__content">
        <div className="p-static">
          <div className="p-relative full-height">
            <div className="sticky-container__sticky_subHeader doc-list-search">
              <Row>
                <SearchWithinText debouncedText={debouncedText} />
              </Row>
              <Row style={{ marginTop: 8 }} gutter={8}>
                <SearchByName debouncedName={debouncedName} />
                <SearchByExtensions debouncedExtensions={debouncedExtensions} />
                <SearchByDate setMinDate={setMinDate} setMaxDate={setMaxDate} />
              </Row>
              <Row style={{ marginTop: 8 }}>
                <Col xs={0} md={16}></Col>
                <DocumentSortDropdown setSort={setSort} sort={sort} en={en} />
                <Col xs={12} md={3} style={{ textAlign: "right" }}>
                  <LayoutSwitcher
                    layout={layout}
                    layoutOptions={[Layout.tile, Layout.list]}
                    changeLayout={setLayout}
                  />
                </Col>
              </Row>
            </div>

            <BulkDownloadDrawer
              bulkDownload={bulkDownload}
              bulkIds={bulkIds}
              bulkLoading={bulkLoading}
              setBulkIds={setBulkIds}
            />

            <div className="doc-list__section-wrapper text-center">
              <div className="main-tile-list doc-tile-list">
                {error && JSON.stringify(error)}
                {data &&
                  data.documents.items.map(doc => (
                    <DocumentComponent
                      key={doc.id}
                      addToBulk={addToBulk}
                      doc={doc}
                      layout={layout}
                      removeFromBulk={removeFromBulk}
                      bulkIds={bulkIds}
                      setSpecificChatDocumentId={setSpecificChatDocumentId}
                    />
                  ))}
                <InfiniteScroller
                  isLoading={loading}
                  loadMore={loadMore}
                  hasMore
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
