import Icon from "@ant-design/icons";
import { toggleExpandedFavoritesSidebar } from "_sirius/src/actions/general_actions";
import { Button, Tag } from "antd";
import { ContextualChat } from "Components/ContextualChat";
import FavoritesList from "Components/FavoritesPanel/FavoritesList";
import InvestmentSetForm from "Components/InvestmentSetForm";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { heightValue } from "Selectors/header_selectors";

enum PannelEnum {
  FavoritesList = "FavoritesList",
  ContextualChat = "ContextualChat",
}

function SideBar() {
  const dispatch = useDispatch();
  const { investmentId } = useParams();
  const navBarHeight = useSelector(heightValue);
  const favoritesSidebarExpanded = useSelector(
    (state: any) => state.app.favoritesSidebarExpanded
  );
  const [action, setAction] = useState(false);
  const caretDirection = favoritesSidebarExpanded ? "right" : "left";
  const panelState = favoritesSidebarExpanded ? "shown" : "hidden";

  const [currentPannel, setCurrentPannel] = useState<PannelEnum>(
    PannelEnum.FavoritesList
  );

  const pannelI = Object.values(PannelEnum).indexOf(currentPannel);

  if (favoritesSidebarExpanded) {
    document.body.classList.add("favorites-panel-shown");
  } else {
    document.body.classList.remove("favorites-panel-shown");
  }

  const Pannels = useMemo<
    {
      key: PannelEnum;
      title: string | JSX.Element;
      body: ({
        buttonAction,
        setButtonAction,
      }: {
        buttonAction?: boolean;
        setButtonAction?: (value: boolean) => void;
      }) => JSX.Element;
      button?: { iconClass: string };
      exclude?: boolean;
      large?: boolean;
    }[]
  >(
    () =>
      [
        {
          key: PannelEnum.FavoritesList,
          title: "Lists",
          body: ({
            buttonAction,
            setButtonAction,
          }: {
            buttonAction?: boolean;
            setButtonAction?: (value: boolean) => void;
          }) => {
            return (
              <>
                <FavoritesList />
                {buttonAction && (
                  <InvestmentSetForm
                    handleCancel={() => {
                      setButtonAction && setButtonAction(false);
                    }}
                    visible={!!buttonAction}
                  />
                )}
              </>
            );
          },
          button: {
            iconClass: "icon icon-plus",
          },
        },
        {
          key: PannelEnum.ContextualChat,
          title: (
            <>
              Contextual Chat <Tag color="#108ee9">Beta</Tag>
            </>
          ),
          body: () => {
            return <ContextualChat />;
          },
          large: true,
          exclude: !investmentId,
        },
      ].filter(({ exclude }) => !exclude),
    [investmentId]
  );

  const current = Pannels.find(({ key }) => key === currentPannel);

  return (
    <div
      id="main-sidebar"
      data-cy="main-sidebar"
      className={`main-sidebar ml-10 main-sidebar--${panelState} ${
        current?.large ? "main-sidebar__large" : ""
      }`}
      style={{
        height: `calc(100% - ${navBarHeight}px)`,
        top: `${navBarHeight}px`,
      }}
    >
      <div className="main-sidebar__header ">
        <button
          className="main-sidebar__toggle-btn"
          onClick={() => {
            dispatch(toggleExpandedFavoritesSidebar());
          }}
          type="button"
        >
          <i className={`fa fa-caret-${caretDirection}`} aria-hidden="true" />
        </button>

        <div className="main-sidebar__tabs">
          <div
            id="lists"
            data-tab="lists"
            className="main-sidebar__tabs-tab--active main-sidebar__tabs-tab d-flex justify-between mb-0"
          >
            <h4 className="flex-grow">
              <i
                className={`fa fa-caret-left pannel__select`}
                aria-hidden="true"
                style={{ visibility: pannelI === 0 ? "hidden" : "visible" }}
                onClick={() => {
                  setCurrentPannel(Object.values(PannelEnum)[pannelI - 1]);
                }}
              />
              {current?.title}
              <i
                className={`fa fa-caret-right pannel__select`}
                aria-hidden="true"
                style={{
                  visibility:
                    pannelI === Pannels.length - 1 ? "hidden" : "visible",
                }}
                onClick={() => {
                  setCurrentPannel(Object.values(PannelEnum)[pannelI + 1]);
                }}
              />
            </h4>

            {current?.button && (
              <Button
                onClick={() => {
                  setAction(true);
                }}
                className="ml-auto"
                size="small"
                type="primary"
                shape="circle"
                icon={
                  <Icon
                    component={() => (
                      <i className={current?.button?.iconClass} />
                    )}
                  />
                }
              />
            )}
          </div>
        </div>
      </div>
      {current?.body({ buttonAction: action, setButtonAction: setAction })}
    </div>
  );
}

export default SideBar;
