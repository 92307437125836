import { Button, Tooltip } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { useState } from "react";

export enum SheetFormat {
  Xlsx = "XLSX",
  Csv = "CSV",
}

const getMaxColWidth = (
  data: (number | string | null | undefined)[][]
): { wch: number }[] => {
  const colCount = Math.max(...data.map(row => row.length));
  const widths: number[] = [];
  for (let i = 0; i < colCount; i++) {
    widths.push(
      Math.max(...data.map(row => (row[i] ? `${row[i]}`.length : 0)))
    );
  }
  return widths.map(n => ({ wch: n }));
};

const spreadSheetFormatter = (
  d: number | string | null | undefined,
  index: number
) => {
  if (typeof d === "string" && index > 2) {
    if (d.includes("%")) {
      const pFloat = parseFloat(d) / 100;
      if (Number.isNaN(pFloat)) {
        return d;
      } else {
        return pFloat;
      }
    }
  }
  return d;
};

export const downloadSpreadsheet = async (
  sheets: {
    name: string;
    data: (number | string | null | undefined)[][];
  }[],
  fileName: string,
  format: SheetFormat
): Promise<void> => {
  const { utils, writeFile } = await import("xlsx");
  const wb = utils.book_new();
  wb.Props = {
    Title: "Sheet Download",
    Subject: fileName,
    Author: "Sirius",
  };

  sheets.forEach(({ data, name }) => {
    const formattedData = data.map(row => row.map(spreadSheetFormatter));

    wb.SheetNames.push(name);
    wb.Sheets[name] = utils.aoa_to_sheet(formattedData);
  });

  switch (format) {
    case SheetFormat.Xlsx:
      sheets.forEach(({ name, data }) => {
        wb.Sheets[name]["!cols"] = getMaxColWidth(data); // for Excel exports make column lengths fit content
      });
      writeFile(wb, `${fileName}.xlsx`);
      break;
    case SheetFormat.Csv:
      sheets.forEach(({ name }) => {
        writeFile(wb, `${fileName}${name}.csv`, { sheet: name });
      });
      break;
    default:
      break;
  }
};

export const DownloadButtons = ({
  download,
  buttonSize = "large",
}: {
  download: (format: SheetFormat) => void;
  buttonSize?: SizeType;
}): JSX.Element => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className="speed-dial"
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
      data-testid="download-speed-dial"
    >
      <Button
        onClick={() => setExpanded(!expanded)}
        className="toggle"
        type="primary"
        shape="circle"
        size={buttonSize}
        icon={<i style={{ fontSize: "16px" }} className="fa fa-download" />}
      />
      {expanded && (
        <>
          <Tooltip placement="topLeft" title="Download as xlsx">
            <Button
              data-testid="xlsx"
              color="green"
              className="action"
              shape="circle"
              size={buttonSize}
              onClick={() => download(SheetFormat.Xlsx)}
            >
              <i style={{ fontSize: "16px" }} className="fas fa-file-excel" />
            </Button>
          </Tooltip>
          <Tooltip placement="topLeft" title="Download as csv">
            <Button
              data-testid="csv"
              ghost
              className="action"
              shape="circle"
              size={buttonSize}
              onClick={() => download(SheetFormat.Csv)}
            >
              <i style={{ fontSize: "16px" }} className="fas fa-file-csv" />
            </Button>
          </Tooltip>
        </>
      )}
    </div>
  );
};
