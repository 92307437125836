import React, { useMemo } from "react";
import { Table, TableColumnsType } from "antd";
import {
  AssetAllocationItem,
  AssetAllocationItemsByType,
} from "./AllocationTable.component";

type AssetAllocationItemGroup = AssetAllocationItem & {
  children: AssetAllocationItem[] | null;
};

function getGroupTotals(
  data: AssetAllocationItemsByType
): AssetAllocationItemGroup[] {
  // get group totals
  const groups: AssetAllocationItemGroup[] = Object.entries(data).map(
    ([groupName, items], index) => ({
      key: `group${index.toString()}`,
      name: groupName || "Accounting",
      isLinked: false,
      endAllocation: items.reduce(
        (acc, item) => acc + (item.endAllocation || 0),
        0
      ),
      weight: items.reduce((acc, item) => acc + (item.weight || 0), 0),
      total: items.filter(item => !!item.endAllocation).length,
      children: items,
    })
  );

  // calculate values for total row
  const total = {
    key: "total",
    name: "Total",
    isLinked: false,
    endAllocation: groups.reduce(
      (acc, group) => acc + (group.endAllocation || 0),
      0
    ),
    weight: groups.reduce((acc, group) => acc + (group.weight || 0), 0),
    total: undefined,
    children: null,
  };

  // add investable cash row
  const cashItem = data.Accounting?.find(item => item.name === "Cash");
  const liabilitiesItem = data.Accounting?.find(
    item => item.name === "Liabilities"
  );

  groups.push({
    key: "investableCash",
    name: "Investable Cash",
    isLinked: false,
    endAllocation:
      (cashItem?.endAllocation || 0) + (liabilitiesItem?.endAllocation || 0),
    weight: (cashItem?.weight || 0) + (liabilitiesItem?.weight || 0),
    total: undefined,
    children: null,
  });

  // add total row last so it appears at the bottom of the table
  groups.push(total);

  return groups;
}

function ExpandableStatisticsTable({
  columnHeaders,
  data,
  loading,
}: {
  columnHeaders: TableColumnsType<AssetAllocationItem>;
  data: AssetAllocationItemsByType;
  loading: boolean;
}): JSX.Element {
  const itemGroupColumns: TableColumnsType<AssetAllocationItemGroup> = [
    Table.EXPAND_COLUMN,
    ...columnHeaders.map(column => ({
      ...column,
      ellipsis: false,
      fixed: false,
    })),
  ];

  const groupTotals = useMemo(() => getGroupTotals(data), [data]);

  return (
    <Table
      data-cy="asset-allocation-table"
      columns={itemGroupColumns}
      indentSize={0}
      dataSource={groupTotals}
      loading={loading}
      pagination={false}
      size="small"
      onRow={(record: AssetAllocationItemGroup) => ({
        style: {
          background: record.children?.length
            ? "#DFDFFF"
            : record.name === "Total"
            ? "#BFBFFF"
            : record.name === "Investable Cash"
            ? "#DDFFDD"
            : undefined,
        },
      })}
    />
  );
}

export default ExpandableStatisticsTable;
