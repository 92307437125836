import { gql } from "_graphql-types/gql";

export const FETCH_PORTFOLIO_BALANCES = gql(/* GraphQL */ `
  query getPortfolioBalances(
    $portfolioId: Int!
    $startDate: Date!
    $endDate: Date!
  ) {
    balancesList(
      filter: {
        portfolioId: $portfolioId
        modelStartDate: $startDate
        modelStopDate: $endDate
        includeAccountingEntities: true
      }
      sort: [{ field: date, order: DESC }]
    ) {
      items {
        date
        endBalance
        managerId
        manager {
          id
          name
          isEntitled
          strategy {
            id
            name
            code
          }
          managerTags(
            filter: { portfolioId: $portfolioId }
            sort: [{ field: date, order: DESC }]
          ) {
            items {
              portfolioId
              managerId
              tag
              tag2
              tag3
              tag4
            }
          }
          vPortfolioManagers(filter: { portfolioId: $portfolioId }) {
            portfolioId
            managerId
            isLinked
          }
        }
      }
    }
  }
`);

export const FETCH_ACCOUNT_MANAGER_DATA = gql(/* GraphQL */ `
  query getAccountingManagerData {
    accountingManagerList {
      items {
        id
        name
      }
    }
  }
`);

export const ALLOCATION_BALANCE_DATES = gql(/* GraphQL */ `
  query getAllocationBalanceDates($portfolioId: Int!) {
    balanceDates(portfolioId: $portfolioId)
  }
`);

export const FETCH_PORTFOLIO_DATA = gql(/* GraphQL */ `
  query getPortfolioData($filter: PortfolioFilter!) {
    portfolioList(filter: $filter) {
      items {
        id
        name
      }
    }
  }
`);
