/* eslint-disable camelcase */
/* eslint-disable max-lines-per-function */
import React, { useState, useMemo } from "react";
import { useQuery } from "@apollo/client";

import { capitalize } from "lodash";
import LoadingPanel from "Components/loading_panel";
import {
  Get_Assetclass_ExposureQuery,
  Get_Regional_ExposureQuery,
  Get_Sector_ExposureQuery,
  PositionType,
} from "_graphql-types/graphql";
import { ensureDefined } from "frontend/src/utils/helpers";
import ExposureSlider from "./slider";
import {
  GET_REGIONAL_EXPOSURE,
  GET_ASSETCLASS_EXPOSURE,
  GET_SECTOR_EXPOSURE,
} from "./graphql";
import ExposureHighChart from "./ExposureHighChart.component";
import ExposurePieChart from "./PieCharts.component";
import ExposureColumnChart from "./ColumnCharts.component";
import {
  isAssetClassExposure,
  isRegionalExposure,
  isSectorExposure,
} from "./typeGuards";

const positionTypes = ["NET", "GROSS", "GROSS %", "LONG", "SHORT"];

export enum ExposureTypes {
  regional = "regional",
  sector = "sector",
  assetClass = "assetClass",
}

function getPositionType(position: PositionType | string): PositionType {
  switch (position) {
    case "NET":
      return PositionType.Net;
    case "GROSS":
      return PositionType.Gross;
    case "GROSS %":
      return PositionType.Gross;
    case "LONG":
      return PositionType.Long;
    case "SHORT":
      return PositionType.Short;
    default:
      throw Error(`Unsupported positionType ${position}`);
  }
}

function getChartData(
  data:
    | Get_Assetclass_ExposureQuery
    | Get_Regional_ExposureQuery
    | Get_Sector_ExposureQuery,
  exposureType: ExposureTypes
) {
  if (isAssetClassExposure(data, exposureType)) {
    return ensureDefined(data.investment.exposure).assetClass;
  }
  if (isRegionalExposure(data, exposureType)) {
    return ensureDefined(data.investment.exposure).regional;
  }
  if (isSectorExposure(data, exposureType)) {
    return ensureDefined(data.investment.exposure).sector;
  }
  throw Error(`Unsupported exposureType ${exposureType}`);
}

const Exposure = ({
  investmentId,
  exposureType,
  label,
  chartType,
}: {
  investmentId: number;
  exposureType: ExposureTypes;
  label: string;
  chartType: string;
}) => {
  const [categoryId, setCategoryId] = useState(3);
  const [positionType, setPositionType] = useState("NET");

  const getExposure = () => {
    switch (exposureType) {
      case "regional":
        return GET_REGIONAL_EXPOSURE;
      case "sector":
        return GET_SECTOR_EXPOSURE;
      case "assetClass":
        return GET_ASSETCLASS_EXPOSURE;
      default:
        throw Error(`Unsupported exposureType ${exposureType}`);
    }
  };

  const { data, error } = useQuery(getExposure(), {
    variables: {
      investmentId,
      categoryId,
      positionType: getPositionType(positionType),
      // default end date to prior month
      endDate: new Date(
        Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), 0)
      ),
    },
  });

  const RenderTabs = () => (
    <div className="chart-controls" data-testid="exposure-chart-controls">
      <ExposureSlider
        level={categoryId}
        onAfterChange={(v: number) => setCategoryId(v)}
        maxLevel={4}
      />
      <div className="chart-controls--options">
        {positionTypes.map(name => (
          <div
            key={name}
            className={`option ${
              name === positionType ? "highlighted-option" : ""
            }`}
            data-cy={`${name}-option`}
            onClick={() => setPositionType(name)}
          >
            {
              name.replace(
                /\w+/g,
                capitalize
              ) /* start case removes special characters */
            }
          </div>
        ))}
      </div>
    </div>
  );

  const rawChartData = useMemo(() => {
    if (!data) return undefined;
    if (!data.investment.exposure) return undefined;
    const exposure = getChartData(data, exposureType);
    return exposure;
  }, [data]);

  if (chartType === "pie") {
    return (
      <div className="invt-tab__section overview-page__card">
        <LoadingPanel loading={!rawChartData}>
          <ExposurePieChart
            percentDisplay={positionType === "GROSS %"}
            label={label}
            rawData={rawChartData || []}
          />
        </LoadingPanel>
        <RenderTabs />
      </div>
    );
  }

  if (chartType === "column") {
    return (
      <div className="invt-tab__section overview-page__card">
        <LoadingPanel loading={!rawChartData} error={error}>
          <ExposureColumnChart
            percentDisplay={positionType === "GROSS %"}
            label={label}
            rawData={rawChartData || []}
          />
        </LoadingPanel>
        <RenderTabs />
      </div>
    );
  }

  return (
    <div className="invt-tab__section overview-page__card">
      <LoadingPanel loading={!rawChartData} error={error}>
        <ExposureHighChart
          percentDisplay={positionType === "GROSS %"}
          label={label}
          rawData={rawChartData || []}
        />
      </LoadingPanel>
      <RenderTabs />
    </div>
  );
};

export default Exposure;
