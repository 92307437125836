import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { DatePicker as AntdDatePicker } from "antd";
import StatisticsTable from "Components/show/overview/statistics_table";
import { lastDayOfMonth } from "date-fns";
import { toPercent } from "Helpers/index";
import i18n from "i18next";
import moment, { Moment } from "moment";
import momentConfig from "rc-picker/lib/generate/moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { POSITION_REPORT_DATES } from "../InvestmentPrivatePositionsTable/graphql";
import { FETCH_INVESTMENT_POSITIONS } from "./graphql";

const DatePicker = AntdDatePicker.generatePicker(momentConfig);

/* eslint-disable-next-line max-lines-per-function */
function InvestmentPositionsTable({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element {
  const [date, setDate] = useState<Date | null>(null);

  const { loading, error, data } = useQuery<
    types.InvestmentPrivatePositionsQuery,
    types.InvestmentPrivatePositionsQueryVariables
  >(FETCH_INVESTMENT_POSITIONS, {
    variables: {
      positionFilter: {
        reportDate: date,
      },
      page: {
        limit: 100,
      },
      sort: [
        {
          field: types.PositionSortEnum.Weight,
          order: types.SortInput.Desc,
        },
      ],
      id: investmentId,
    },
    skip: !date,
  });

  const { data: datesData, loading: datesLoading } = useQuery<
    types.GetPositionReportDatesQuery,
    types.GetPositionReportDatesQueryVariables
  >(POSITION_REPORT_DATES, {
    variables: {
      investmentId,
    },
  });

  useEffect(() => {
    if (datesData && datesData.positionReportDates[0]) {
      setDate(new Date(datesData.positionReportDates[0]));
    }
  }, [datesData]);

  const name = data?.investment?.name || "";
  const items = useMemo(() => data?.investment?.positions?.items || [], [data]);

  const updateDate = (newDate: moment.Moment | null) => {
    if (!newDate) return;
    const endOfMonth = lastDayOfMonth(newDate.toDate());
    setDate(endOfMonth);
  };

  const disabledTime = useCallback(
    (selectionDate: Moment) =>
      !datesData?.positionReportDates
        .map(dateDatum => new Date(dateDatum))
        .some(
          (dateDatum: Date) =>
            selectionDate.month() === dateDatum.getMonth() &&
            selectionDate.year() === dateDatum.getFullYear()
        ),
    [datesData]
  );

  const positions = useMemo(
    () =>
      items.map(item => ({
        ...item,
        weight: toPercent(item.weight),
      })),
    [items]
  );

  if (error) {
    return <span>Something went wrong fetching positions</span>;
  }

  return (
    <div>
      {error && (
        <div className="error">
          Something went wrong, please contact support.
          {JSON.stringify(error, null, 2)}
        </div>
      )}
      <div className="summary-heading" data-cy="positions">
        <h3 className="invt-tab__title" id="positions">
          {i18n.t("overview.positions")}
        </h3>

        <div className="summary-heading__desc-wrap">
          {(loading || datesLoading) && (
            <i
              data-testid="loading-spinner"
              className="fa fa-spinner fa-spin"
            />
          )}
          {date && (
            <>
              <span className="summary-heading__desc-label">
                {i18n.t("date.as_of", { date: "" })}
              </span>

              <div className="rc-calendar-custom-wrap width-120">
                <DatePicker
                  picker="month"
                  format="MMM yyyy"
                  disabledDate={disabledTime}
                  allowClear={false}
                  placeholder="Set the date"
                  defaultValue={moment(date)}
                  onChange={updateDate}
                  data-cy="month-picker"
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div id="positions-table" className="positions-table">
        {positions.length > 0 &&
          `Source Date: ${positions[0].sourceDate.substring(0, 10)}`}
        <div>
          <div id="positions-table" className="positions-table">
            <StatisticsTable
              dataMapping={positions}
              keyOrder={["name", "ticker", "weight"]}
              headers={["Name", "Ticker", "Weighting"]}
              fileName={`${name} ${date?.getFullYear()} ${
                date ? date.getMonth() + 1 : ""
              } positions`}
              sortableKeys={["name", "ticker", "weight"]}
              numberKeys={["weight"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvestmentPositionsTable;
