import React, { useState } from "react";
import { ChatComponent } from "Components/ChatBox";
import { ChatMessage } from "Components/ChatBox/types";
import { useLazyQuery, useSubscription } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ChatToolType } from "_graphql-types/graphql";
import { AGENT_CHAT, CONTEXTUAL_CHAT_SUBSCRIPTION } from "./graphql";
import { ToolDisplays } from "./toolDisplays";
import { Spin, Checkbox, Row, Col, Card } from "antd";

const getSessionId = () =>
  `${Date.now().toString(36)}::${Math.random().toString(36).slice(2)}`;

const tools_display = [
  {
    tool: ChatToolType.DocumentsRagTool,
    name: "Document RAG",
    description: "Augments response with Document content",
  },
  {
    tool: ChatToolType.NotesRagTool,
    name: "Note RAG",
    description: "Augments response with Note content",
  },
];

export function ContextualChat() {
  const { investmentId } = useParams();

  const [sessionId, setSessionId] = useState(getSessionId());

  const [chatHistory, changeChatHistory] = useState<ChatMessage[]>([]);
  const [userText, setUserText] = useState("");
  const [conversationId, changeConversationId] = useState<number | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [tools, setTools] = useState<ChatToolType[]>([
    ChatToolType.NotesRagTool,
    ChatToolType.DocumentsRagTool,
  ]);

  const [sendChat, { loading }] = useLazyQuery(AGENT_CHAT, {
    variables: {
      question: userText,
      conversationId,
      chatContext: { investmentId: Number(investmentId) },
      tools,
      sessionId,
    },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      setIsGenerating(false);
      return;
    },
    onError(error) {
      changeChatHistory([
        ...chatHistory,
        {
          type: "agent",
          data: {
            type: "error",
            message: "Error answering your question, please try again",
          },
        },
      ]);
    },
  });

  useSubscription(CONTEXTUAL_CHAT_SUBSCRIPTION, {
    variables: {
      sessionId: sessionId,
    },
    skip: !sessionId,
    onData({ data }) {
      changeChatHistory([
        ...chatHistory.filter(
          x => x.id !== data.data?.contextualChatSubscription.messageStream.id
        ),
        {
          type: "agent",
          id: data.data?.contextualChatSubscription.messageStream.id,
          data: {
            type: "react",
            message: (
              <>
                {data.data?.contextualChatSubscription.answer}{" "}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {data.data?.contextualChatSubscription.tools?.map(
                    ({ tool, params }) => (
                      <ToolDisplays tool={tool} params={params} />
                    )
                  )}
                </div>
              </>
            ),
          },
        },
      ]);
    },
  });

  return (
    <>
      <ChatComponent
        chatMessages={[
          ...chatHistory,
          ...(loading
            ? [
                {
                  type: "agent" as const,
                  data: {
                    type: "react" as const,
                    message: <Spin size="small" style={{ color: "white" }} />,
                  },
                },
              ]
            : []),
        ]}
        agentWorking={isGenerating}
        handleUserMessage={async () => {
          sendChat({
            variables: {
              question: userText,
              conversationId,
              chatContext: { investmentId: Number(investmentId) },
              tools,
              sessionId,
            },
          });
          changeChatHistory([
            ...chatHistory,
            { type: "user", data: { type: "markdown", message: userText } },
          ]);
          setUserText("");
          setIsGenerating(true);
        }}
        cardStyles={{ height: "50vh" }}
        {...{ userText, setUserText }}
        agentCharacter={{
          name: "Contextual Chat",
          src: "/assets/images/docchatIcon.png",
        }}
        onNewChat={() => {
          changeChatHistory([]);
          changeConversationId(null);
          setSessionId(getSessionId());
        }}
      />
      <Card>
        {tools_display.map(tool => (
          <Row key={tool.tool}>
            <Checkbox
              checked={tools.includes(tool.tool)}
              onChange={v =>
                v.target.checked
                  ? setTools([...tools, tool.tool])
                  : setTools(tools.filter(_tool => _tool !== tool.tool))
              }
            >
              {tool.name}
            </Checkbox>
          </Row>
        ))}
      </Card>
    </>
  );
}
