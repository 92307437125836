import React, { useContext, useState, useEffect, Suspense, lazy } from "react";
import { notesIndexContext } from "./NotesIndex.context";
import { Tooltip } from "antd";

export const NoteReportButton = () => {
  const { notesFilters } = useContext(notesIndexContext);
  const [reportURL, setReportURL] = useState("");
  const [buildReport, setBuildReport] = useState(false);
  const Notes = lazy(() => import("Components/fund_report/Notes"));
  useEffect(() => {
    if (reportURL && buildReport) {
      setBuildReport(false);
    }
  }, [reportURL]);
  if (reportURL && buildReport) {
    window.open(reportURL);
    setReportURL("");
    setBuildReport(false);
  }
  return (
    <>
      {buildReport && (
        <Suspense fallback={null}>
          <Notes setReportUrl={setReportURL} notesFilters={notesFilters} />
        </Suspense>
      )}
      <Tooltip placement="right" title={"Top 100 Notes"}>
        <button
          onClick={() => {
            setBuildReport(true);
          }}
          style={{ float: "right" }}
        >
          {buildReport ? (
            <i className="fa fa-spinner fa-spin" />
          ) : (
            <i className="icon icon-print font-15 pb-15 pr-15" />
          )}
        </button>
      </Tooltip>
    </>
  );
};
