/* eslint-disable no-console */
import * as types from "_graphql-types/graphql";
import { Spin, Typography } from "antd";
import classnames from "classnames";
import {
  COMPANY_PATHS,
  INVESTMENT_PATHS,
} from "Components/GlobalSearchWorkspace/GlobalSearchControls.component";
import { CATEGORY_ICON } from "Helpers/global_search_helpers.js";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
import {
  addToEntityFilter,
  updateFilter,
} from "Reducers/globalSearchV2/globalSearchV2.actions";
import {
  EntityFilter,
  Filter,
  unhandledCase,
  unimplemented,
} from "Reducers/globalSearchV2/globalSearchV2.types";
import { OptGroup, Option } from "../GlobalSearchDropdownOptions.component";
import { useDispatch } from "src/react-redux";

export interface AutoCompleteResults {
  investments: {
    loading: boolean;
    data?: types.GetGlobalSearchInvestmentQuery["investment"];
  };
  portfolios: {
    loading: boolean;
    data?: types.GetGlobalSearchInvestmentQuery["investment"];
  };
  firms: {
    loading: boolean;
    data?: types.GetGlobalSearchFirmQuery["firm"];
  };
  notes: {
    loading: boolean;
    data?: types.GetGlobalSearchNoteQuery["note"];
  };
  documents: {
    loading: boolean;
    data?: types.GetGlobalSearchDocumentQuery["document"];
  };
  company: {
    loading: boolean;
    data?: types.GetGlobalSearchCompanyQuery["company"];
  };
}

type AutoCompleteItem = NonNullable<
  AutoCompleteResults[keyof AutoCompleteResults]["data"]
>["items"][number];

function isNote(
  value: AutoCompleteItem
): value is types.GetGlobalSearchNoteQuery["note"]["items"][number] {
  return value.__typename === "Notes";
}

function isDocument(
  value: AutoCompleteItem
): value is types.GetGlobalSearchDocumentQuery["document"]["items"][number] {
  return value.__typename === "Document";
}

function isInvestment(
  value: AutoCompleteItem
): value is types.GetGlobalSearchInvestmentQuery["investment"]["items"][number] {
  return value.__typename === "Investment";
}

function rootPath(category: keyof AutoCompleteResults) {
  switch (category) {
    case "company":
      return COMPANY_PATHS[0];
    case "firms":
    case "investments":
    case "notes":
    case "documents":
    case "portfolios":
      return INVESTMENT_PATHS[0];
    default:
      throw unhandledCase(category);
  }
}

export function CategoryLabel(
  categoryName: keyof AutoCompleteResults,
  loading: boolean,
  overrideCategory?: string
) {
  return (
    <span>
      <span className="search-form__dropdown-menu-header">
        {i18n.t(`global_search.groups.${overrideCategory || categoryName}`)}
      </span>
      {!loading ? null : (
        <span>
          {" "}
          <Spin size="small" />
        </span>
      )}
    </span>
  );
}

function SearchOption({
  filterType,
  searchTerm,
}: {
  filterType: Filter["type"];
  searchTerm: string;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addSearchTermTag = () => {
    switch (filterType) {
      case "investment":
        navigate(rootPath("investments"));
        dispatch(
          updateFilter({
            type: "investment",
            data: {
              type: "investment",
              data: {
                type: "SEARCH_TERM",
                data: {
                  value: searchTerm,
                },
              },
            },
          })
        );
        break;
      case "company":
        navigate(rootPath("company"));
        dispatch(
          updateFilter({
            type: "company",
            data: {
              type: "NAME",
              data: searchTerm,
            },
          })
        );
        break;
      default:
        throw unhandledCase(filterType);
    }
  };

  return (
    <>
      <Option
        key="search-for"
        className="cursor-pointer"
        onClick={addSearchTermTag}
      >
        <i className="search-form__dropdown-menu-icon icon icon-loupe icon--20" />
        <span className="flex-grow text-truncate min-width-0 in-secondary-blue">
          {`Search for "${searchTerm}".`}
        </span>

        <Typography.Text style={{ fontSize: "12px" }}>
          Type * first to search by tags.
        </Typography.Text>
      </Option>
    </>
  );
}

interface EntityOptionsProps {
  category: keyof AutoCompleteResults;
  results: AutoCompleteResults;
  blur: () => void;
}

const categoryEntityFilter =
  (category: keyof AutoCompleteResults) =>
  (id: number, label: string): EntityFilter => {
    switch (category) {
      case "investments":
      case "portfolios":
        return {
          type: "investment",
          data: {
            type: "INVESTMENT_ENTITY",
            data: { id, label },
          },
        };
      case "firms":
        return {
          type: "investment",
          data: {
            type: "FIRM_ENTITY",
            data: { id, label },
          },
        };
      case "notes":
        throw unimplemented();
      case "documents":
        throw unimplemented();
      case "company":
        return {
          type: "company",
          data: {
            type: "COMPANY_ENTITY",
            data: { id, label },
          },
        };
      default:
        throw unhandledCase(category);
    }
  };

function formatNoteDate(date: any) {
  if (date) {
    const val = new Date(date);
    return val.toISOString().slice(0, 10);
  }
  return "-";
}

function optionPath(
  category: keyof AutoCompleteResults,
  item: AutoCompleteItem
) {
  if (category === "portfolios") return `/investments/${item.id}`;
  return `/${category}/${item.id}`;
}

function EntityOptions({ category, results, blur }: EntityOptionsProps) {
  const dispatch = useDispatch();
  if (!results) return null;
  console.log("RESULTS ->", results);
  const { loading, data } = results[category];
  const getEntityFilter = categoryEntityFilter(category);
  const navigate = useNavigate();

  // hide the group unless it is loading or has items
  return !loading && !data?.items.length ? null : (
    <OptGroup key={category} label={CategoryLabel(category, loading)}>
      {!data
        ? null
        : data.items.map(item => (
            <Option
              key={String([category, item.id])}
              onClick={() => {
                blur();
                navigate(optionPath(category, item));
              }}
            >
              <i
                className={`search-form__dropdown-menu-icon icon icon--20 ${CATEGORY_ICON[category]}`}
              />

              <span
                className={classnames([
                  "text-truncate",
                  "min-width-0",
                  isInvestment(item) ? "margin-r-sm" : "flex-grow",
                ])}
              >
                {isNote(item)
                  ? [
                      formatNoteDate(
                        item.noteDate ?? item.modifyDate ?? item.createDate
                      ),
                      item.noteMeta.noteType.noteType,
                      item.investment?.name ?? item.company?.name,
                    ].join(", ")
                  : isDocument(item)
                    ? [
                        formatNoteDate(
                          item.date ?? item.modifyDate ?? item.createDate
                        ),
                        item.businessObject.name,
                        item.name,
                      ].join(", ")
                    : item.name}
              </span>

              {isInvestment(item) && (
                <span className="flex-grow text-truncate min-width-0 in-secondary-blue">
                  - {item.firm?.name}
                </span>
              )}

              <span
                onClick={event => {
                  event.stopPropagation();
                  if (!isNote(item) && item?.id) {
                    dispatch(
                      addToEntityFilter(
                        getEntityFilter(item.id, item.name ?? "")
                      )
                    );
                  } else {
                    console.warn("NO ACCESS TO NOTE");
                  }
                  navigate(rootPath(category));
                }}
                className="add-as-tag"
                data-type={item.__typename}
              >
                <span className="add-as-tag__txt">
                  {i18n.t("global_search.add_as_tag")}
                </span>
                <i className="add-as-tag__icon icon icon-tag icon--20" />
              </span>
            </Option>
          ))}
    </OptGroup>
  );
}

export function GlobalSearchOptions({
  results,
  searchTerm,
  blur,
}: {
  results: AutoCompleteResults;
  searchTerm: string;
  blur: () => void;
}): JSX.Element {
  return (
    <>
      <SearchOption filterType="investment" searchTerm={searchTerm} />
      <EntityOptions category="investments" results={results} blur={blur} />
      <EntityOptions category="portfolios" results={results} blur={blur} />
      <EntityOptions category="company" results={results} blur={blur} />
      <EntityOptions category="firms" results={results} blur={blur} />
      <EntityOptions category="notes" results={results} blur={blur} />
      <EntityOptions category="documents" results={results} blur={blur} />
    </>
  );
}
