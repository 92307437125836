/* eslint-disable max-lines-per-function */
import {
  ApiOutlined,
  CommentOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Spin,
  Tag,
  Tooltip,
  Upload,
  UploadProps,
} from "antd";
import { CSSProperties, Fragment, useCallback, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { ChatMessageHead } from "../ChatPage/ChatMessageHead";
import { ChatMessageItem } from "../ChatPage/ChatMessageItem";
import { ChatMessage } from "./types";

const StyledUpload = styled(Upload)({
  width: "100%",
  height: "50px",
  "& .ant-upload": {
    width: "100% !important",
    height: "50px !important",
    borderRadius: "10px",
  },
});

const MarkdownCard = styled(Card)({
  "& .markdown-li": {
    display: "list-item",
  },
  "& .markdown-ol": {
    listStyle: "decimal outside none",
    display: "block",
    margin: "1em 0",
    padding: "0 0 0 2em",
  },
  "& .markdown-ul": {
    listStyle: "disc outside none",
    display: "block",
    margin: "1em 0",
    padding: "0 0 0 2em",
  },
  "& .markdown-table": {
    width: "initial",
    "& th": {
      fontWeight: "bold",
    },
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: "#f2f2f2",
    },
  },
  "& code": {
    backgroundColor: "#f2f2f2",
    borderRadius: "3px",
    padding: "0 3px",
  },
});

export const ChatComponent = ({
  chatMessages,
  handleUserMessage,
  userText,
  setUserText,
  fileUpload,
  agentWorking,
  responseButtonClickAction,
  cancelAgentFocus,
  cardStyles,
  onNewChat,
  agentCharacter,
  llmOnly,
  setLlmOnly,
  chatScrollRef,
}: {
  chatMessages: (ChatMessage & { key?: string })[];
  handleUserMessage: (text?: string) => Promise<void>;
  userText: string;
  setUserText: (text: string) => void;
  fileUpload?: {
    customRequest: UploadProps["customRequest"];
  };
  responseButtonClickAction?: (value: string | undefined) => void;
  agentWorking: boolean;
  cancelAgentFocus?: () => void;
  cardStyles?: CSSProperties;
  onNewChat?: () => void;
  agentCharacter: {
    name: string;
    src: string;
  };
  llmOnly?: boolean;
  setLlmOnly?: (value: boolean) => void;
  chatScrollRef?: React.RefObject<HTMLDivElement>;
}) => {
  const [localChatId, setLocalChatId] = useState(0);
  const [fileList, setFileList] = useState<any[]>([]);

  const localOnNewChat = useCallback(() => {
    setLocalChatId(prev => prev + 1);
    setUserText("");
    setFileList([]);
    onNewChat?.();
  }, [onNewChat, setUserText, setFileList, setLocalChatId]);

  const { ref, width, height } = useResizeDetector({
    refreshMode: "debounce",
    refreshRate: 100,
  });

  return (
    <Row>
      <Col span={24}>
        <MarkdownCard
          ref={ref}
          style={{
            borderRadius: "10px",
            width: "100%",
          }}
        >
          <div
            data-cy="chat-messages"
            style={{
              overflowY: "auto",
              width: "100%",
              margin: "10px",
              height: "calc(100vh - 345px)",
              ...cardStyles,
            }}
          >
            <Row style={{ marginRight: "10px" }}>
              {chatMessages.map((message, imessage) => (
                <Fragment key={message.key ?? imessage}>
                  {message.type !== chatMessages[imessage - 1]?.type && (
                    <ChatMessageHead
                      type={message.type}
                      separator={!!imessage}
                      agentCharacter={agentCharacter}
                    />
                  )}
                  <ChatMessageItem
                    message={message}
                    onResponseButtonClick={responseButtonClickAction}
                  />
                </Fragment>
              ))}
            </Row>
            <div ref={chatScrollRef} />
          </div>
          <Row>
            <Input.TextArea
              value={userText}
              style={{
                width: `100%`,
                borderRadius: "5px",
              }}
              placeholder="Message Sirius Chat"
              data-cy="chat-input"
              autoSize={{ minRows: 1, maxRows: 10 }}
              onChange={event => setUserText(event.target.value)}
              onKeyPress={event => {
                if (event.key === "Enter" && !event.shiftKey) {
                  event.preventDefault();
                  handleUserMessage();
                }
              }}
              // don't use disabled because it loses focus
              readOnly={agentWorking}
            />
            <div className="d-flex justify-between mt-10 full-width">
              <div>
                {onNewChat && (
                  <Tooltip title="Start A New Chat">
                    <Button
                      key={"newChat"}
                      onClick={localOnNewChat}
                      type="default"
                      shape="circle"
                      icon={<CommentOutlined />}
                      data-cy="new-chat-button"
                    ></Button>
                  </Tooltip>
                )}
                {setLlmOnly && llmOnly !== undefined && (
                  <Tooltip
                    title={
                      !llmOnly
                        ? "Toggle to send requests to LLM Only."
                        : "Toggle to send requests to LLM and Knowledge Base."
                    }
                  >
                    <Tag.CheckableTag
                      style={{
                        fontSize: "14px",
                        height: "32px",
                        minWidth: "32px",
                        paddingRight: 0,
                        paddingLeft: 0,
                        textAlign: "center",
                        borderRadius: " 50%",
                        width: "32px",
                        fontFamily: "inherit",
                        backgroundColor: "white",
                        color: "black",
                      }}
                      checked={llmOnly}
                      data-cy="llm-only-request-toggle"
                      onChange={checked => {
                        setLlmOnly(checked);
                      }}
                    >
                      <ApiOutlined style={{ marginTop: "7px" }} />
                    </Tag.CheckableTag>
                  </Tooltip>
                )}
              </div>
              {agentWorking && cancelAgentFocus ? (
                <Button
                  type="default"
                  style={{
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  onClick={cancelAgentFocus}
                >
                  Stop
                </Button>
              ) : agentWorking ? (
                <Button
                  type="default"
                  style={{
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  disabled
                >
                  <Spin
                    size="small"
                    indicator={<LoadingOutlined spin />}
                    style={{ fontSize: 14 }}
                  />
                </Button>
              ) : (
                <Button
                  type="primary"
                  style={{
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  onClick={() => handleUserMessage()}
                >
                  Send
                </Button>
              )}
            </div>
          </Row>
        </MarkdownCard>
      </Col>
      <Col span={24}></Col>
      {fileUpload && (
        <Col span={24}>
          <StyledUpload
            key={localChatId}
            data-cy="chat-file-upload"
            name="upload"
            multiple
            listType="picture-card"
            showUploadList={false}
            disabled={agentWorking}
            customRequest={fileUpload.customRequest}
            onChange={info => {
              setFileList(info.fileList);
            }}
          >
            <UploadOutlined />
            &nbsp; {fileList.length > 0 && `(${fileList.length})`} Drag or click
            to upload {fileList.length > 0 ? `another` : "a"} file.
          </StyledUpload>
        </Col>
      )}
    </Row>
  );
};
